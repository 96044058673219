import http from '../utils/http-client';
import { store } from "core/store";
import { start, stop } from 'core/store/loader/reducer';
import { setMeubles } from 'core/store/meuble/reducer';

const source = 'meuble'

class MeubleService {
    all(id: any) {
        store.dispatch(start());
        return new Promise(async (resolve, reject) => {
            try {
                const rs = await http.get(`${source}/bien/${id}`);
                store.dispatch(setMeubles(rs?.data?.data));
                store.dispatch(stop());
                resolve(rs?.data?.data);
            } catch (error) {
                store.dispatch(stop());
                reject(error);
            }
        });
    }

    one(id: any, immeuble: any) {
        return new Promise(async (resolve, reject) => {
            try {
                let meuble = null;
                if (store.getState().meuble.data?.length <= 0) {
                    const rs: any = await this.all(immeuble);
                    meuble = rs?.find((meuble: any) => meuble?.id === +id);
                } else {
                    store.dispatch(start());
                    meuble = store.getState().meuble.data?.find((meuble: any) => meuble?.id === +id);
                }
                store.dispatch(stop());
                resolve(meuble);
            } catch (error) {
                store.dispatch(stop());
                reject(error);
            }
        });
    }

    reserver(id: any, data: any) {
        return new Promise(async (resolve, reject) => {
            store.dispatch(start());
            try {
                const rs = await http.post(`${source}/${id}/reserver`, data);
                store.dispatch(stop());
                resolve(rs);
            } catch (error) {
                store.dispatch(stop());
                reject(error);
            }
        });
    }

    check(id: any, data: any) {
        return new Promise(async (resolve, reject) => {
            try {
                const rs = await http.post(`${source}/${id}/check`, data);
                resolve(rs.data);
            } catch (error) {
                reject(error);
            }
        });
    }
}

export default new MeubleService();