import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

import Alert from '../../core/utils/alert'
import authService from "core/services/auth.service";

import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import FormItem from "shared/FormItem/FormItem";

export interface PageSignUpProps {
  className?: string;
}

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {

  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
    defaultValues: {
      civilite: "M",
      nom: null,
      prenom: null,
      email: null,
      telephone: null,
      password: null,
    }
  });

  const history = useHistory();

  const handelInscription = async (data: any) => {
    data.role = 'CLIENT';
    const rs: any = await authService.register(data);
    Alert.fire({
      title: 'Bienvenue!',
      text: rs.data.message,
      icon: 'success',
      showCancelButton: false,
      confirmButtonText: 'Okay'
    }).then((result) => {
      if (result.isConfirmed) {
        history.push('/login');
      }
    })
  }

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Inscription - LMDC Lounge</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Inscription
        </h2>
        <div className="max-w-lg mx-auto space-y-6 ">
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(handelInscription)}>
            <FormItem
              label="Civilite"
              className="block"
            >
              <Select
                defaultValue="M"
                {...register("civilite")}
                placeholder="Civilite"
                className="mt-1"
                onChange={(e) => {
                  setValue("civilite", e.target.value);
                }}
              >
                <option value="M">Monsieur</option>
                <option value="Mme">Madame</option>
                <option value="Mlle">Mademoiselle</option>
              </Select>
            </FormItem>

            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <FormItem
                label="Nom"
                className="block"
                error={errors.nom && "Le nom est obligatoire !"}
                required
              >
                <Input
                  error={errors.nom ? true : false}
                  type="text"
                  {...register("nom", { required: true })}
                  placeholder="Ex: John"
                  className="mt-1"
                />
              </FormItem>

              <FormItem
                label="Prenom"
                className="block"
              >
                <Input
                  {...register("prenom")}
                  type="text"
                  placeholder="Ex: Sammuel"
                  className="mt-1"
                />
              </FormItem>
            </div>

            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <FormItem
                label="Email"
                className="block"
                error={errors.email && "L'email est obligatoire !"}
                required
              >
                <Input
                  error={errors.email ? true : false}
                  {...register("email", { required: true })}
                  type="email"
                  placeholder="Ex: example@example.com"
                  className="mt-1"
                />
              </FormItem>

              <FormItem
                label="Téléphone"
                className="block"
              >
                <Input
                  {...register("telephone")}
                  type="number"
                  placeholder="Ex: 6********"
                  className="mt-1"
                />
              </FormItem>
            </div>
            <FormItem
              label="Mot de passe"
              className="block"
              error={errors.password && "Le mot de passe est obligatoire !"}
              required
            >
              <Input
                error={errors.password ? true : false}
                {...register("password", { required: true })}
                type="password"
                className="mt-1"
              />
            </FormItem>

            <ButtonPrimary type="submit">S'inscrire</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            J'ai déjà un compte? {` `}
            <Link to="/login">Se conneter</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
