import React, { FC, useMemo, useState } from "react";
import StayCard from "components/StayCard/StayCard";
import SiteSkeleton from "components/SkeletonCard/SiteSkeleton";
import Pagination from "shared/Pagination/Pagination";
import Heading2 from "components/Heading/Heading2";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: any[];
  url?: string
}

const PageSize = 8;

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data = [],
  url
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const currentData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return data?.length > 0 ? data?.slice(firstPageIndex, lastPageIndex) : [];
  }, [currentPage, data]);

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2 heading="Explorer nos sites" subHeading={(
        <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
          {data?.length > 0 ? `${data?.length} sites` : `Aucun site`}
        </span>
      )} />


      {data?.length <= 0 ? (
        <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {[1, 2, 3].map((stay) => (
            <SiteSkeleton key={stay} />
          ))}
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {currentData.map((stay) => (
              stay?.immeubles_count > 0 && (
                <StayCard key={stay.id} data={stay} />
              )
            ))}
          </div>
          <div className="flex mt-16 justify-center items-center">
            <Pagination
              currentPage={currentPage}
              totalCount={data?.length}
              pageSize={PageSize}
              onPageChange={page => setCurrentPage(page)}
            />
          </div>
        </>
      )}
    </div >
  );
};

export default SectionGridFilterCard;
