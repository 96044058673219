import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { RouteComponentProps } from "react-router-dom";

import siteService from "core/services/site.service";

import siteImgJpg from "images/hero-right-3.png";

export interface ListingSitePageProps extends RouteComponentProps {
  className?: string;
}

const ListingSitePage: FC<ListingSitePageProps> = ({ className = "", match }) => {

  const [sites, setSites] = useState<any>([]);

  const loadSites = () => {
    siteService.all().then(
      (rs: any) => {
        setSites(rs);
      }
    );
  }

  useEffect(() => {
    loadSites();
  }, []);

  return (
    <div
      className={`nc-ListingSitePage relative overflow-hidden ${className}`}
      data-nc-id="ListingSitePage"
    >
      <Helmet>
        <title>Sites - LMDC Lounge</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative overflow-hidden">
        {/* SECTION HERO */}
        <SectionHeroArchivePage
          head="Sites"
          listingType={(
            <React.Fragment>
              <i className="text-2xl las la-home"></i>
              <span className="ml-2.5">{sites?.length > 0 ? `${sites?.length} sites` : `Aucun sites`}</span>
            </React.Fragment>
          )}
          image={siteImgJpg}
          currentPage="Site"
          currentTab="Site"
          className="pt-10 pb-5 lg:pb-10 lg:pt-24 "
        />

        {/* SECTION */}
        <SectionGridFilterCard data={sites} url={match.url} className="pb-24 lg:pb-32" />

        {/* SECTION */}
        <SectionSubscribe2 className="pb-24 lg:pb-32" />
      </div>
    </div>
  );
};

export default ListingSitePage;
