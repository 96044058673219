import { AppState } from './../index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CaveState } from "./types";


const initialState: CaveState = {
    data: []
}

export const caveSlice = createSlice({
    name: 'cave',
    initialState,
    reducers: {
        setCaves: (state, action: PayloadAction<any[]>) => {
            state.data = action.payload
        }
    },
})


export const { setCaves } = caveSlice.actions

export const getCaves = (state: AppState) => state.cave.data

export default caveSlice.reducer