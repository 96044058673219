import { AppState } from './../index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthState } from "./types";
import localstorage from '../../utils/localstorage';


const initialState: AuthState = {
    token: localstorage.get('token') ?? '',
    connected: localstorage.exist('token'),
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload
        },

        removeToken: (state) => {
            state.token = ''
        },

        userConnect: (state) => {
            state.connected = true
        },

        userDisconnect: (state) => {
            state.connected = false
        }
    },
})


export const { setToken, removeToken, userConnect, userDisconnect } = authSlice.actions

export const getToken = (state: AppState) => state.auth.token

export const isConnected = (state: AppState) => state.auth.connected

export default authSlice.reducer