import React, { FC } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
// import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useForm } from "react-hook-form";
import userService from "core/services/user.service";
import FormItem from "shared/FormItem/FormItem";
import Alert from '../../core/utils/alert'

// import SectionClientSay from "components/SectionClientSay/SectionClientSay";
// import BackgroundSection from "components/BackgroundSection/BackgroundSection";

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "🗺 ADRESSE",
    desc: "Bonapriso, Armée de l'air / Nouvelle route Bastos",
  },
  {
    title: "💌 EMAIL",
    desc: "lamaison_ducognac@invest--partners.com",
  },
  {
    title: "☎ TELEPHONE",
    desc: "(+237) 655 449 734 / 651 725 759",
  },
];

const socialsDemo: any[] = [
  { name: "Facebook", icon: "lab la-facebook-square", href: "https://www.facebook.com/lecocondenayki" },
];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {

  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      nom_complet: null,
      email: null,
      message: null
    }
  });

  const handelContact = async (data: any) => {
    await userService.contact(data).then(
      (rs: any) => {
        Alert.fire({
          title: 'Message envoyé!',
          text: rs?.message,
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'Okay'
        });
      }
    );
  }

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Contact - LMDC Lounge</title>
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contactez nous
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 RESEAUX SOCIAUX
                </h3>
                <SocialsList socials={socialsDemo} className="mt-2" />
              </div>
            </div>
            <div>
              <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(handelContact)}>
                <FormItem
                  label="Nom"
                  className="block"
                  error={errors.email && "Votre nom est obligatoire !"}
                  required
                >
                  <Input
                    error={errors.nom_complet ? true : false}
                    type="text"
                    {...register("nom_complet", { required: true })}
                    placeholder="Nom complet"
                    className="mt-1"
                  />
                </FormItem>
                <FormItem
                  label="Email"
                  className="block"
                  error={errors.email && "L'email est obligatoire !"}
                  required
                >
                  <Input
                    error={errors.email ? true : false}
                    type="email"
                    {...register("email", { required: true })}
                    placeholder="Ex: example@example.com"
                    className="mt-1"
                  />
                </FormItem>
                <FormItem
                  label="Message"
                  className="block"
                  error={errors.email && "Le message est obligatoire !"}
                  required
                >
                  <Textarea
                    error={errors.email ? true : false}
                    {...register("message", { required: true })}
                    placeholder="Message"
                    className="mt-1"
                  />
                </FormItem>
                <div>
                  <ButtonPrimary type="submit">Envoyer le message</ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      <div className="container">
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div> */}
        <SectionSubscribe2 className="pb-24 lg:pb-32" />
      </div>
    </div>
  );
};

export default PageContact;
