import { AppState } from './../index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BienState } from "./types";


const initialState: BienState = {
    data: []
}

export const bienSlice = createSlice({
    name: 'bien',
    initialState,
    reducers: {
        setBiens: (state, action: PayloadAction<any[]>) => {
            state.data = action.payload
        }
    },
})


export const { setBiens } = bienSlice.actions

export const getBiens = (state: AppState) => state.bien.data

export default bienSlice.reducer