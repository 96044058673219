import React, { FC } from "react";
import rightImgPng from "images/our-features.png";
import NcImage from "shared/NcImage/NcImage";
import { CSSProperties } from "styled-components";

export interface SectionPresentationProps {
  className?: string;
  rightImg?: string;
  head?: string;
  type?: "type1" | "type2";
  imgStyle?: CSSProperties
}

const SectionPresentation: FC<SectionPresentationProps> = ({
  className = "",
  rightImg = rightImgPng,
  head = '',
  type = "type1",
  imgStyle = {},
  ...args
}) => {
  const { children } = args;
  return (
    <div
      className={`nc-SectionPresentatoin relative flex flex-col ${type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
        } ${className}`}
    >
      <div className={`flex-grow flex items-center ${type === "type1" ? "justify-center" : "justify-center"}`}>
        <NcImage
          src={rightImg}
          containerClassName="shadow-2xl"
          className="object-cover w-full h-full rounded-lg shadow-2xl"
          style={imgStyle}
        />
      </div>
      <div
        className={`flex flex-col justify-center max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-3/5 ${type === "type1" ? "lg:pl-16" : "lg:pr-16"
          }`}
      >
        <h2 className="font-semibold text-4xl mt-5 text-center lg:text-left"> {head} </h2>

        <div className="space-y-10 mt-6 text-center lg:text-left">
          {children}
        </div>
      </div>
    </div>
  );
};

export default SectionPresentation;
