import React, { FC } from "react";
import Hero1 from "images/slide_meuble_1.jpg";
import Hero3 from "images/slide_meuble_2.jpg";

import BannerAnim, { Element } from 'rc-banner-anim';
import TweenOne from 'rc-tween-one';
import 'rc-banner-anim/assets/index.css';

const BgElement = Element.BgElement;

export interface SectionHero2Props {
  className?: string;
}

const SectionHero2: FC<SectionHero2Props> = ({ className = "", children }) => {
  return (
    <div className="banner">
      <BannerAnim prefixCls="banner-user" component="div">
        <Element 
          prefixCls="banner-user-elem"
          key="0"
        >
          <BgElement
            key="banner-user-elem-bg-1"
            className="banner-user-elem-bg"
           >
             <img src={Hero1} alt="Banner 1"/>
          </BgElement>

          <div className="banner-user-elem-text">
            <TweenOne className="text-3xl font-semibold md:text-5xl mb-2 md:mb-4" animation={{ y: 30, opacity: 0, type: 'from' }}>
              Vos meublés
            </TweenOne>
            <TweenOne className="text-1xl md:text-2xl" 
              animation={{ y: 30, opacity: 0, type: 'from', delay: 100 }}
            >
              Lorsque confort et bien-être ne font plus qu'un.
            </TweenOne>
          </div>
        </Element>

        <Element 
          prefixCls="banner-user-elem"
          key="1" 
        >
          <BgElement
            key="banner-user-elem-bg-2"
            className="banner-user-elem-bg"
          >
            <img src={Hero3} alt="Banner 2" />
          </BgElement>
          <div className="banner-user-elem-text">
            <TweenOne className="text-3xl font-semibold md:text-5xl mb-2 md:mb-4" animation={{ y: 30, opacity: 0, type: 'from' }}>
              Vos Caves
            </TweenOne>
            <TweenOne className="text-1xl md:text-2xl" 
              animation={{ y: 30, opacity: 0, type: 'from', delay: 100 }}
            >
              Découvrez des saveurs insoupçonnées.
            </TweenOne>
          </div>
        </Element>
      </BannerAnim>
    </div>
    
      );
  
};

export default SectionHero2;
