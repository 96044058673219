import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridFilterCard from "./SectionGridFilterCard";
import caveService from "core/services/cave.service";

export interface ListingCavePageProps {
  className?: string;
}

const ListingCavePage: FC<ListingCavePageProps> = ({
  className = "",
}) => {

  const [caves, setCaves] = useState([]);

  const loadCaves = () => {
    caveService.all().then(
      (rs: any) => {
        setCaves(rs);
      }
    );
  }

  useEffect(() => {
    loadCaves();
  }, []);

  return (
    <div
      className={`nc-ListingCavePage relative overflow-hidden ${className}`}
      data-nc-id="ListingCavePage"
    >
      <Helmet>
        <title>Caves - LMDC Lounge</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative">
        {/* SECTION HERO */}
        <SectionHeroArchivePage
          head="Caves"
          listingType={
            <React.Fragment>
              <i className="text-2xl las la-umbrella-beach"></i>
              <span className="ml-2.5">{caves?.length > 0 ? `${caves?.length} cave(s)` : `Aucune cave`}</span>
            </React.Fragment>
          }
          className="pt-10 pb-5 lg:pb-10 lg:pt-24 "
        />

        {/* SECTION */}
        <SectionGridFilterCard data={caves} className="pb-24 lg:pb-32" />

        {/* SECTION */}
        <SectionSubscribe2 className="pb-24 lg:pb-32" />
      </div>
    </div>
  );
};

export default ListingCavePage;
