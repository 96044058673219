import axios from "axios";
import localStorage from './localstorage';

import { removeToken, userDisconnect } from '../store/auth/reducer';
import { store } from '../store'
import Alert from './alert'
import history from './history'

const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
        'Accept': 'application/json',
        'Content-type': "application/json"
    }
});

instance.interceptors.request.use(
    (req: any) => {
        const token = localStorage.get('token');
        if (token !== null) {
            req.headers['Authorization'] = `Bearer ${token}`;
        }
        return req;
    },
    (error: any) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (rs: any) => {
        return rs;
    },
    (error: any) => {
        const originalConfig = error.config;
        if (!originalConfig.url.includes("found") && error.response) {
            if (error.response.status === 401) {
                store.dispatch(removeToken());
                store.dispatch(userDisconnect());
                localStorage.remove('user');
                localStorage.remove('token');
                history.push('/login', { from: window.location.pathname });
                window.location.reload();
            } else {
                const errorMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                Alert.fire({
                    icon: 'error',
                    title: 'Oops !! une erreur s\'est produite',
                    text: errorMessage,
                    footer: '<a href="mailto:someone@yoursite.com?subject=Besoin d\'une assistance technique">Contacter-nous pour une assistance technique</a>'
                });
            }
            console.error(error.response);
            return Promise.reject(error);
        }
    }
);

export default instance;