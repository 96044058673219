import http from '../utils/http-client';

const source = 'reservation'

class MailService {
    reservation(id: any) {
        return new Promise(async (resolve, reject) => {
            try {
                const rs = await http.get(`${source}/${id}/mail`);
                resolve(rs?.data?.data);
            } catch (error) {
                reject(error);
            }
        });
    }
}

export default new MailService();