import http from '../utils/http-client';
import { store } from "core/store";
import { start, stop } from 'core/store/loader/reducer';

const source = 'table'

class TableService {
    reserver(id: any, data: any) {
        return new Promise(async (resolve, reject) => {
            store.dispatch(start());
            try {
                const rs = await http.post(`${source}/${id}/reserver`, data);
                store.dispatch(stop());
                resolve(rs);
            } catch (error) {
                store.dispatch(stop());
                reject(error);
            }
        });
    }

    check(id: any, data: any) {
        return new Promise(async (resolve, reject) => {
            try {
                const rs = await http.post(`${source}/${id}/check`, data);
                resolve(rs.data);
            } catch (error) {
                reject(error);
            }
        });
    }
}

export default new TableService();