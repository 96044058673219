import { AppState } from './../index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SiteState } from "./types";


const initialState: SiteState = {
    data: [],
    popular: []
}

export const siteSlice = createSlice({
    name: 'site',
    initialState,
    reducers: {
        setSites: (state, action: PayloadAction<any[]>) => {
            state.data = action.payload
        },

        setSitesPopular: (state, action: PayloadAction<any[]>) => {
            state.popular = action.payload
        }
    },
})


export const { setSites, setSitesPopular } = siteSlice.actions

export const getSites = (state: AppState) => state.site.data

export const getPopular = (state: AppState) => state.site.popular

export default siteSlice.reducer