import React, { useState } from "react";
import { FC } from "react";
import { useEffect } from "react";
import { useRef } from "react";

export interface DureInputProps {
  defaultValue?: number;
  onChange?: (value: number) => void;
  onInputDone?: (value: number) => void;
  className?: string;
  fieldClassName?: string
}

const DureInput: FC<DureInputProps> = ({
  defaultValue = 0,
  onChange,
  onInputDone,
  className = "nc-flex-1.5",
  fieldClassName = "[ nc-hero-field-padding ]",
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState(defaultValue);
  const [autoFocus, setAutoFocus] = useState(false);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    autoFocus && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [autoFocus]);

  useEffect(() => {
    onChange && onChange(value);
  }, [value]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;

    if (!autoFocus || containerRef.current.contains(event.target as Node)) {
      return;
    }

    setAutoFocus(false);
  };

  const handleDure = (item: number) => {
    setValue(item);
    onInputDone && onInputDone(item);
    setAutoFocus(false);
  };

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => setAutoFocus(true)}
        className={`flex w-full relative ${fieldClassName} rounded-2xl flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left 
        ${autoFocus ? "shadow-2xl" : ""
          }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <i className="las la-user-clock la-2x"></i>
        </div>
        <div className="flex-grow">
          <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
            <span className="line-clamp-1">Durée</span>
          </span>
          <input
            className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
            placeholder="Duré"
            type="number"
            inputMode="numeric"
            value={value ? value : ''}
            min={0}
            autoFocus={autoFocus}
            onChange={(e) => handleDure(+e.currentTarget.value)}
            ref={inputRef}
          />
        </div>
      </div>
    </div>
  );
};

export default DureInput;
