import React, { FC, useEffect, useState, useRef, forwardRef } from "react";
import MainDatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import moment from "moment";
import 'moment/locale/fr'
import fr from 'date-fns/locale/fr';
import "react-datepicker/dist/react-datepicker.css";

registerLocale('fr', fr)

export interface MeubleDateSingleInputProps {
  defaultValue?: Date | null;
  onChange?: (value: Date | null) => void;
  onInputDone?: (value: Date | null) => void;
  className?: string;
  fieldClassName?: string;
  dateFormat?: string;
  showTimeSelect?: boolean;
  excludeDates?: Date[];
  highlightDates?: Date[];
  excludeTimes?: Date[];
  displayFormat?: string
}

type PropsInput = {
  onClick?: () => void,
  value?: any
}

const MeubleDateSingleInput: FC<MeubleDateSingleInputProps> = ({
  defaultValue = null,
  onChange = (date: Date | null) => { },
  onInputDone = (date: Date | null) => { },
  className = "nc-flex-1.5",
  fieldClassName = "[ nc-hero-field-padding ]",
  showTimeSelect = false,
  dateFormat = "dd/MM/yyyy",
  displayFormat = "LL",
  excludeDates = [],
  highlightDates = [],
  excludeTimes = []
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [value, setValue] = useState(defaultValue);
  const [autoFocus, setAutoFocus] = useState(false);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    autoFocus && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [autoFocus]);

  useEffect(() => {
    onChange && onChange(value);
  }, [value]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;

    if (!autoFocus || containerRef.current.contains(event.target as Node)) {
      return;
    }

    setAutoFocus(false);
  };

  const handleDateChange = (item: Date) => {
    setValue(item);
    onInputDone && onInputDone(item);
    setAutoFocus(false);
  };

  const CustomInput = forwardRef(({ onClick }: PropsInput, ref: any) => {
    return (
      <div
        onClick={onClick}
        ref={ref}
        className={`flex w-full relative ${fieldClassName} rounded-2xl flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left 
      ${autoFocus ? "shadow-2xl" : ""
          }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
        <i className="las la-calendar-day la-2x"></i>
        </div>
        <div className="flex-grow">
          <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
            <span className="line-clamp-1">Date reservation</span>
          </span>
          <span className="block xl:text-lg font-semibold" onClick={onClick}>
            {value ? (<span className="capitalize">{moment(value).locale('fr').format(displayFormat)}</span>) : "Choisir une date"}
          </span>

        </div>
      </div>
    );
  });

  return (
    <div className={`relative flex ${className}`} ref={containerRef} onClick={() => setAutoFocus(true)}>
      <MainDatePicker
        className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
        selected={value}
        minDate={new Date()}
        excludeDates={excludeDates}
        highlightDates={highlightDates}
        excludeTimes={excludeTimes}
        onChange={(date: any) => handleDateChange(date)}
        timeFormat="HH:mm"
        showTimeSelect={showTimeSelect}
        dateFormat={dateFormat}
        locale="fr"
        customInput={<CustomInput />}
      />
    </div>
  );
};

export default MeubleDateSingleInput;
