import { usePagination, DOTS } from "hooks/usePagination";
import React, { FC } from "react";
// import { Link } from "react-router-dom";

export interface PaginationProps {
  className?: string;
  totalCount?: number,
  siblingCount?: number,
  currentPage?: number,
  pageSize?: number,
  onPageChange?: (page: number) => void;
}

const Pagination: FC<PaginationProps> = ({
  className = "",
  totalCount = 0,
  siblingCount = 1,
  currentPage = 1,
  pageSize = 6,
  onPageChange = (index: number) => { }
}) => {

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  const isCurrentPage = (check: boolean) => {
    if(check)
      return 'bg-primary-6000 text-white';
    else
      return 'text-primary-6000 bg-white border border-secondary-6000';
  }

  const lastPage = paginationRange[paginationRange.length - 1];

  const onNext = () => {
    if (currentPage !== lastPage)
      onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    if (currentPage !== 1)
      onPageChange(currentPage - 1);
  };

  const renderItem = (pag: any, index: number) => {
    if (pag === DOTS) {
      return (
        <span
          key={index}
          className={`inline-flex w-11 h-11 items-center justify-center rounded-full text-primary-6000 bg-white border border-primary-6000 hover:shadow-xl transition-shadow`}
        >
          &#8230;
        </span>
      );
    }

    return (
      <span
        key={index}
        className={`inline-flex w-11 h-11 items-center justify-center cursor-pointer rounded-full hover:shadow-xl transition-shadow ${isCurrentPage(pag === currentPage)}`}
        onClick={() => onPageChange(pag)}
      >
        {pag}
      </span>
    );
  };

  return (
    <nav
      className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
    >
      <span
        className={`inline-flex w-auto px-8 cursor-pointer h-11 items-center justify-center rounded-full bg-primary-6000 text-white hover:shadow-xl transition-shadow`}
        onClick={onPrevious}
      >
        <i className="text-1xl las la-angle-double-left"></i>
        {/* <i className="text-2xl las la-home"></i> */}
      </span>
      {paginationRange.map(renderItem)}
      <span
        className={`inline-flex w-auto px-8 cursor-pointer h-11 items-center justify-center rounded-full bg-primary-6000 text-white hover:shadow-xl transition-shadow`}
        onClick={onNext}
      >
        {/* <i className="text-2xl las la-home"></i> */}
        <i className="text-1xl las la-angle-double-right"></i>
      </span>
    </nav>
  );
};

export default Pagination;
