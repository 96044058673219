import http from '../utils/http-client';
import { store } from "core/store";
import { start, stop } from 'core/store/loader/reducer';
import { setBiens } from 'core/store/bien/reducer';

const source = 'bien'

class BienService {
    all(id: any) {
        store.dispatch(start());
        return new Promise(async (resolve, reject) => {
            try {
                const rs = await http.get(`${source}/site/${id}`);
                store.dispatch(setBiens(rs?.data?.data));
                store.dispatch(stop());
                resolve(rs?.data?.data);
            } catch (error) {
                store.dispatch(stop());
                reject(error);
            }
        });
    }

    one(id: any, site: any) {
        return new Promise(async (resolve, reject) => {
            try {
                let immeuble = null;
                if (store.getState().bien.data?.length <= 0) {
                    const rs: any = await this.all(site);
                    immeuble = rs?.find((immeuble: any) => immeuble?.id === +id);
                } else {
                    store.dispatch(start());
                    immeuble = store.getState().bien.data?.find((cave: any) => cave?.id === +id);
                }
                store.dispatch(stop());
                resolve(immeuble);
            } catch (error) {
                store.dispatch(stop());
                reject(error);
            }
        });
    }
}

export default new BienService();