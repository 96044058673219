import { AppState } from './../index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MeubleState } from "./types";


const initialState: MeubleState = {
    data: []
}

export const meubleSlice = createSlice({
    name: 'meuble',
    initialState,
    reducers: {
        setMeubles: (state, action: PayloadAction<any[]>) => {
            state.data = action.payload
        }
    },
})


export const { setMeubles } = meubleSlice.actions

export const getMeubles = (state: AppState) => state.cave.data

export default meubleSlice.reducer