import Label from "components/Label/Label";
import React from "react";
import { FC } from "react";

export interface FormItemProps {
    className?: string;
    label?: string;
    desc?: string;
    error?: string | null
    required?: boolean,
    onClick?: () => void
}

const FormItem: FC<FormItemProps> = ({
    children,
    className = "",
    label,
    desc,
    error = null,
    required = false,
    onClick
}) => {
    return (
        <div className={className} onClick={onClick}>
            {label && <Label>
                {label}
                {required && (
                    <small className="text-red-500 dark:text-red-400 text-xs ml-1">*</small>
                )}
            </Label>}
            <div className="mt-1">{children}</div>
            {desc && (
                <span className="block mt-3 text-xs text-neutral-500 dark:text-neutral-400 ">
                    {desc}
                </span>
            )}
            {error && (
                <span className="block mt-1 text-xs text-red-500 dark:text-red-400 ">
                    {error}
                </span>
            )}
        </div>
    );
};

export default FormItem;
