import React, { FC, useState, useEffect, Fragment } from "react";
import useWindowSize from "hooks/useWindowResize";
import { Helmet } from "react-helmet";
import moment from "moment";
import CheckBox from "shared/Checkbox/Checkbox";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import ModalPhotos from "./ModalPhotos";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import MeubleDateSingleInput from "components/HeroSearchForm/MeubleDateSingleInput";
import DateDisponible from "components/DateDisponible/DateDisponible";
import DureInput from "components/HeroSearchForm/DureInput";
import PeriodiciteInput from "components/HeroSearchForm/PeriodiciteInput";

import caveService from "core/services/cave.service";
import tableService from "core/services/table.service";
import payService from "core/services/payment.service";
import mailService from "core/services/mail.service";

import Alert from "core/utils/alert";
import { useAppSelector } from '../../core/store/index';
import placeholder from '../../images/cave-placeholder.png';
import { useParams } from "react-router-dom";
import { Dialog, Transition, RadioGroup } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import DatePicker from "shared/DatePicker/DatePicker";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import FormItem from "shared/FormItem/FormItem";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { TrashIcon } from "@heroicons/react/solid";

import NumberFormat from 'react-number-format';
export interface ListingCaveDetailPageProps {
  className?: string;
}

const ListingCaveDetailPage: FC<ListingCaveDetailPageProps> = ({
  className = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [isOpenModalReserveTable, setIsOpenModalReserveTable] = useState(false);
  const [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);
  const [isOpenModalTables, setIsOpenModalTables] = useState(false);

  const user = useAppSelector(state => state.user.data);
  const isConnected = useAppSelector(state => state.auth.connected);
  const [cave, setCave] = useState<any>(null);
  const [table, setTable] = useState<any>(null);

  const [isAcceptReserve, setIsAcceptReserve] = useState(false);
  const [isPossibleReserveCave, setIsPossibleReserveCave] = useState(false);
  const [checkReserveDateCave, setCheckReserveDateCave] = useState(false);
  const [reservationButtonTextCave, setReservationButtonTextCave] = useState('Reserver la cave');

  const [isAcceptReserveTable, setIsAcceptReserveTable] = useState(false);
  const [isPossibleReserveTable, setIsPossibleReserveTable] = useState(false);
  const [checkReserveDateTable, setCheckReserveDateTable] = useState(false);
  const [reservationButtonTextTable, setReservationButtonTextTable] = useState('Reserver la table');

  const [duree, setDuree] = useState(0);
  const [periodicite, setPeriodicite] = useState('DEMI-JOUR');
  const [prixTotal, setPrixTotal] = useState(0);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const [dureeTable, setDureeTable] = useState(0);
  const [periodiciteTable, setPeriodiciteTable] = useState('DEMI-JOUR');
  const [prixTotalTable, setPrixTotalTable] = useState(0);
  const [selectedDateTable, setSelectedDateTable] = useState<Date | null>(null);

  const [boisson, setBoisson] = useState<any>(null);
  const [quantite, setQuantite] = useState(0);
  const [prixTotalCommande, setPrixTotalCommande] = useState(0);
  const [commande, setCommade] = useState<any[]>([]);

  const [images, setImages] = useState<string[]>([]);
  const [othersImages, setOthersImages] = useState<string[]>([]);
  const [firstImage, setFirstImage] = useState<any>(placeholder);

  const [reservations, setReservations] = useState<any[]>([]);

  const windowSize = useWindowSize();

  const { cave: caveId } = useParams<{ cave?: any }>();

  const loadCave = () => {
    caveService.one(caveId).then(
      (data: any) => {
        formImages(data);
        formOthersImages(data);
        setReservations(data?.reservations_encours);
        if (!data?.site?.reglement) {
          setIsAcceptReserve(true);
          setIsAcceptReserveTable(true);
        }
        if (data?.image_principale) {
          setFirstImage(data?.image_principale?.path);
        }
        setCave(data);
      }
    );
  };

  const formImages = (data: any) => {
    let result = data?.images?.map((elt: any) => {
      return elt?.path
    });
    if (result?.length <= 0) {
      result = [placeholder]
    }
    setImages(result);
  };

  const formOthersImages = (data: any) => {
    let result = data?.others_images?.map((elt: any) => {
      return elt?.path
    });
    setOthersImages(result);
  };

  const isDateAvailableCave = () => {

    if (!selectedDate) {
      return;
    }

    setCheckReserveDateCave(true);

    setReservationButtonTextCave('Verification de la date');

    caveService.check(caveId, { date_debut: moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'), periode: periodicite }).then(
      (rs: any) => {
        setCheckReserveDateCave(false);
        setIsPossibleReserveCave(!rs.data.free);
        setReservationButtonTextCave('Reserver la cave');
        if (!rs.data.free) {
          Alert.fire({
            title: 'Vérification de la disponibilité!',
            allowOutsideClick: false,
            allowEscapeKey: false,
            text: rs.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'Changer de date'
          });
          setReservationButtonTextCave('Changer la date');
        }
      },
      (error: any) => {
        setCheckReserveDateCave(false);
      }
    )
  };

  const isDateAvailableTable = () => {

    if (!selectedDateTable) {
      return;
    }

    setCheckReserveDateTable(true);

    setReservationButtonTextTable('Verification de la date');

    tableService.check(table.id, { date_debut: moment(selectedDateTable).format('YYYY-MM-DD HH:mm:ss'), periode: periodiciteTable }).then(
      (rs: any) => {
        setCheckReserveDateTable(false);
        setIsPossibleReserveTable(!rs.data.free);
        setReservationButtonTextTable('Reserver la table');
        if (!rs.data.free) {
          Alert.fire({
            title: 'Vérification de la disponibilité!',
            allowOutsideClick: false,
            allowEscapeKey: false,
            text: rs.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'Changer de date'
          });
          setReservationButtonTextTable('Changer la date');
        }
      },
      (error: any) => {
        setCheckReserveDateTable(false);
      }
    )
  };

  useEffect(() => {
    isDateAvailableCave();
  }, [selectedDate]);

  useEffect(() => {
    isDateAvailableTable();
  }, [selectedDateTable]);

  useEffect(() => {
    loadCave();
  }, [caveId]);

  useEffect(() => {
    let total = 0;
    commande.forEach(item => {
      total += item?.prix;
    });
    setPrixTotalCommande(total);
  }, [commande]);

  useEffect(() => {
    switch (periodicite) {
      case 'NUITEE':
        setPrixTotal(duree * cave?.prix_nuit_min);
        break;
      default:
        setPrixTotal(cave?.prix_jour_min);
        break;
    }
  }, [periodicite]);

  useEffect(() => {
    setPrixTotal(duree * cave?.prix_nuit_min);
  }, [duree]);

  useEffect(() => {
    switch (periodicite) {
      case 'NUITEE':
        setPrixTotal(duree * cave?.prix_nuit_min);
        break;
      default:
        setPrixTotal(cave?.prix_jour_min);
        break;
    }
  }, [cave]);

  useEffect(() => {
    setPrixTotalTable(dureeTable * table?.prix_nuit);
  }, [dureeTable]);

  useEffect(() => {
    switch (periodiciteTable) {
      case 'NUITEE':
        setPrixTotalTable(dureeTable * table?.prix_nuit);
        break;
      default:
        setPrixTotalTable(table?.prix_jour);
        break;
    }
  }, [periodiciteTable]);

  useEffect(() => {
    switch (periodiciteTable) {
      case 'NUITEE':
        setPrixTotalTable(dureeTable * table?.prix_nuit);
        break;
      default:
        setPrixTotalTable(table?.prix_jour);
        break;
    }
  }, [table]);

  const handleBoissonChange = (id: any) => {
    if (!id) {
      setBoisson(null);
      return;
    }
    const boisson = cave?.boissons[id];
    setBoisson(boisson);
  }

  const handleAddBoissonCommande = () => {
    let newCommande = [...commande];

    if (!boisson || !quantite) {
      return;
    }

    const data = {
      boisson: boisson,
      quantite: quantite,
      prix: (boisson?.prix_event ? boisson?.prix_event : boisson?.prix) * quantite
    };

    if (data.boisson?.quantite < data.quantite) {
      alert('Stock insuffisant');
      return;
    }

    const exist = existInCommande(data);

    if (exist > -1) {
      const added = newCommande[exist]?.quantite + quantite;
      if (data.boisson?.quantite < added) {
        alert('Stock insuffisant');
        return;
      }

      handleUpdateBoissonCommande(added, exist)
    } else {
      newCommande.push(data);
      setCommade(newCommande);
    }
  }

  const handleUpdateBoissonCommande = (qte: any, id: any) => {
    let newCommande = [...commande];
    newCommande[id].quantite = +qte;
    newCommande[id].prix = (newCommande[id]?.boisson?.prix_event ? newCommande[id]?.boisson?.prix_event : newCommande[id]?.boisson?.prix) * +qte;
    setCommade(newCommande);
  }

  const handleRemoveBoissonCommande = (id: any) => {
    let newCommande = [...commande];
    newCommande.splice(id, 1);
    setCommade(newCommande);
  }

  const existInCommande = (data: any) => {
    let found = -1;
    commande.forEach((element: any, index: any) => {
      if (element?.boisson.id === data?.boisson.id) {
        found = index;
      }
    });
    return found;
  };

  const getDaySize = () => {
    if (windowSize.width <= 375) {
      return 34;
    }
    if (windowSize.width <= 500) {
      return undefined;
    }
    if (windowSize.width <= 1280) {
      return 56;
    }
    return 48;
  };

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  function closeModalTables() {
    setIsOpenModalTables(false);
  }

  function openModalTables() {
    setIsOpenModalTables(true);
  }

  const closeModalReserveTable = () => {
    setIsOpenModalReserveTable(false);
  }

  const openModalReserveTable = () => {
    setIsOpenModalReserveTable(true);
  }

  const reserveTable = (table: any) => {
    setTable(table);
    openModalReserveTable();
  }

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const handlePayment = async (reservation?: any) => {
    const { value: numero } = await Alert.fire({
      title: 'Paiement de votre reservation',
      text: 'Entrez votre numéro de téléphone de votre compte mobile money',
      allowOutsideClick: false,
      allowEscapeKey: false,
      input: 'text',
      inputPlaceholder: 'Numéro de téléphone',
      inputValue: user?.telephone,
      showCancelButton: false,
      confirmButtonText: 'Payer',
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (!value) {
            resolve('Numéro de téléphone requis!')
          }
          resolve(null)
        })
      }
    });


    const data = {
      numero,
      montant: reservation.prix_total,
      message: `votre réservation ${reservation.reference} sur la plateforme LMDC Lounge`
    };

    payService.pay(data).then(
      (rs: any) => {
        const data = {
          methode: (rs?.operator as string).toUpperCase(),
          trans_id: rs?.reference,
          numero
        };

        payService.save(reservation.id, data);

        Alert.fire(
          'Merci pour votre réservation!',
          `<p>Validez votre paiement en tapant ${rs?.ussd_code} sur votre téléphone.</p>
          <p>Veuillez nous contacter pour la validation de votre réservation</p>
          <p><strong>Téléphone : ${cave?.telephone}</strong></p>
          <p><strong>Email : ${cave?.email}</strong></p>`,
          'info'
        )
      },
      (error: any) => {
        console.error(error);

        Alert.fire({
          allowOutsideClick: false,
          allowEscapeKey: false,
          icon: 'error',
          title: 'Oops !! le paiement a échoué',
          text: 'Il se peut que votre compte ne soit pas suffisamment approvisionné pour traiter le paiement. Voulez-vous réessayer ?',
          footer: '<a href="mailto:someone@yoursite.com?subject=Besoin d\'une assistance technique">Contacter-nous pour une assistance technique</a>',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: 'Réessayer',
          denyButtonText: 'Annuler',
        }).then(async (result) => {
          if (result.isConfirmed) {
            handlePayment(reservation);
          }
        });
      }
    );
  };

  const initReservationCave = () => {
    setDuree(0);
    setPeriodicite('DEMI-JOUR');
    setPrixTotal(cave?.prix_jour_min);
    setSelectedDate(null);
  }

  const initReservationTable = () => {
    setDureeTable(0);
    setPeriodiciteTable('DEMI-JOUR');
    setPrixTotalTable(table?.prix_jour);
    setSelectedDateTable(null);
    setBoisson(null);
    setQuantite(0);
    setPrixTotalCommande(0);
    setCommade([]);
  }

  const handleReserveTable = () => {
    if (periodiciteTable === 'NUITEE' && dureeTable <= 0) {
      Alert.fire({
        title: 'Attention!',
        text: 'Quelle est la durée de votre reservation ?',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Remplir'
      });
      return;
    }

    if (!selectedDateTable) {
      Alert.fire({
        title: 'Attention!',
        text: 'Quand occuperez-vous la table ?',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Remplir'
      });
      return;
    }

    const data = {
      duree: dureeTable,
      periodicite: periodiciteTable,
      prix_total: prixTotalTable,
      prix_total_commande: prixTotalCommande,
      commande: commande,
      etat: 'IMPAYER',
      type: 'TABLE',
      user_id: user?.id,
      staut: 'TOTALITE',
      date_debut: moment(selectedDateTable)?.format('YYYY-MM-DD HH:mm:ss'),
      date_reservsation: moment().format('YYYY-MM-DD HH:mm:ss'),
      site_id: cave?.site_id,
      cave_id: cave?.id
    }

    tableService.reserver(table?.id, data).then(
      (rs: any) => {
        closeModalReserveTable();
        initReservationTable();
        mailService.reservation(rs.data?.data?.reservation?.id);
        Alert.fire({
          title: 'Réservation effectuée!',
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: rs.data.message,
          icon: 'success',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: 'Payer en ligne',
          denyButtonText: 'Payer en espèce',
        }).then((result) => {
          if (result.isConfirmed) {
            handlePayment(rs.data?.data?.reservation);
          } else if (result.isDenied) {
            Alert.fire('Merci pour votre réservation!', `<p>Veuillez nous contacter pour la validation de votre réservation</p> <p><strong>Téléphone : ${cave?.telephone}</strong></p> <p><strong>Email : ${cave?.email}</strong></p>`, 'info')
          }
        });
      }
    )
  };

  const handleReserveCave = () => {
    if (periodicite === 'NUITEE' && duree <= 0) {
      Alert.fire({
        title: 'Attention!',
        text: 'Quelle est la durée de votre reservation ?',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Remplir'
      });
      return;
    }

    if (!selectedDate) {
      Alert.fire({
        title: 'Attention!',
        text: 'Quand occuperez-vous la cave ?',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Remplir'
      });
      return;
    }

    const data = {
      duree: duree,
      periodicite: periodicite,
      prix_total: prixTotal,
      etat: 'IMPAYER',
      type: 'CAVE',
      user_id: user?.id,
      staut: 'TOTALITE',
      date_debut: moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'),
      date_reservsation: moment().format('YYYY-MM-DD HH:mm:ss')
    }

    caveService.reserver(cave?.id, data).then(
      (rs: any) => {
        initReservationCave();
        mailService.reservation(rs.data?.data?.reservation?.id);
        Alert.fire({
          title: 'Réservation effectuée!',
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: rs.data.message,
          icon: 'success',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: 'Payer en ligne',
          denyButtonText: 'Payer en espèce',
        }).then((result) => {
          if (result.isConfirmed) {
            handlePayment(rs.data?.data?.reservation);
          } else if (result.isDenied) {
            Alert.fire('Merci pour votre réservation!', `<p>Veuillez nous contacter pour la validation de votre réservation</p> <p><strong>Téléphone : ${cave?.telephone}</strong></p> <p><strong>Email : ${cave?.email}</strong></p>`, 'info')
          }
        });
        setReservations(rs.data.data?.reservation_encours);
      }
    )
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {cave?.nom}
        </h2>

        <div className="flex flex-wrap text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-2 my-2" style={{ paddingRight: '2.5rem' }}>
            <i className="las la-at"></i>
            <span> {cave?.email}</span>
          </div>
          <div className="flex items-center space-x-2 my-2" style={{ paddingRight: '2.5rem' }}>
            <i className="las la-phone"></i>
            <span> {cave?.telephone}</span>
          </div>
          <div className="flex items-center space-x-2 my-2" style={{ paddingRight: '2.5rem' }}>
            <i className="las la-map-marker-alt"></i>
            <span> {cave?.site?.adresse}, {cave?.adresse}</span>
          </div>
        </div>

        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        <div className="flex items-center flex-wrap text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-2 my-2">
            <i className="las la-table text-2xl"></i>
            <span className="ml-1">{cave?.tables?.length} tables</span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Descriptions</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300" dangerouslySetInnerHTML={{ __html: cave?.description }}></div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Boissons </h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {cave?.boissons?.filter((_: any, i: any) => i < 12).map((item: any, index: any) => (
            <div key={index} className="flex items-center space-x-3">
              <i className="las la-wine-bottle text-2xl"></i>
              <span>{item.boisson?.libelle} =&gt; <NumberFormat defaultValue={0} value={item?.prix_event ? item?.prix_event : item?.prix} displayType={'text'} thousandSeparator={' '} suffix={' FCFA'} /></span>
            </div>
          ))}
        </div>
        <div className="w-14 border-b border-neutral-200"></div>
        <div>
          <ButtonSecondary onClick={openModalAmenities}>
            Voir toutes les boissons
          </ButtonSecondary>
        </div>
        {renderModalAmenities()}
      </div >
    );
  };

  const renderModalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full">
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Toutes les boissons
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 py-6 overflow-auto grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
                    {cave?.boissons?.map((item: any, index: any) => (
                      <div key={index} className="flex items-center space-x-3">
                        <i className="las la-wine-bottle text-2xl"></i>
                        <span>{item.boisson?.libelle} =&gt; <NumberFormat defaultValue={0} value={item?.prix_event ? item?.prix_event : item?.prix} displayType={'text'} thousandSeparator={' '} suffix={' FCFA'} /></span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderModalTables = () => {
    return (
      <Transition appear show={isOpenModalTables} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalTables}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full">
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Toutes les tables
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalTables} />
                    </span>
                  </div>
                  <div className="px-8 py-6 overflow-auto grid grid-cols-1 gap-6 md:gap-8 md:grid-cols-2 lg:grid-cols-3">
                    {cave?.tables?.map((item: any, index: any) => (
                      <div key={index} className="p-3 space-y-2 border border-neutral-400 shadow-xl rounded-lg">
                        <div className="space-y-2">
                          <div className="flex items-center space-x-2 py-2 pt-0">
                            <h2
                              className={`font-medium capitalize text-base`}
                            >
                              <span className="line-clamp-1">{item?.designation}</span>
                            </h2>
                          </div>
                          <div className="text-sm text-neutral-500 dark:text-neutral-400 py-2 pt-0">
                            {item?.position} . {item?.nombre_siege} siége(s)
                          </div>
                          {isConnected && <ButtonPrimary onClick={() => reserveTable(item)} rounded="rounded-md" sizeClass="px-2 py-2 sm:px-4" fontSize="text-xs">Reserver la table</ButtonPrimary>}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSection4 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <div className="flex items-center justify-between">
            <h2 className="text-2xl font-semibold">Tables </h2>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="grid grid-cols-1 gap-6 md:gap-8 md:grid-cols-2 lg:grid-cols-3">
          {cave?.tables?.filter((_: any, i: any) => i < 6).map((item: any, index: any) => (
            <div key={index} className="p-3 space-y-2 border border-neutral-400 shadow-xl rounded-lg">
              <div className="space-y-2">
                <div className="flex items-center space-x-2 py-2 pt-0">
                  <h2
                    className={`font-medium capitalize text-base`}
                  >
                    <span className="line-clamp-1">{item?.designation}</span>
                  </h2>
                </div>
                <div className="text-sm text-neutral-500 dark:text-neutral-400 py-2 pt-0">
                  {item?.position} . {item?.nombre_siege} siége(s)
                </div>
                {isConnected && <ButtonPrimary onClick={() => reserveTable(item)} rounded="rounded-md" sizeClass="px-2 py-2 sm:px-4" fontSize="text-xs">Reserver la table</ButtonPrimary>}

              </div>
            </div>
          ))}
        </div >
        <div className="w-14 border-b border-neutral-200"></div>
        <div>
          <ButtonSecondary onClick={openModalTables}>
            Voir toutes les tables
          </ButtonSecondary>
        </div>
        {renderModalTables()}
        {renderReserveTable()}
      </div>
    );
  };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Prix des reservations de la cave </h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root mb-16">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 mb-4">
            <div className="p-4  flex justify-between items-center space-x-4 rounded-lg">
              <span>Pour une démi-jour</span>
              <span><NumberFormat defaultValue={0} value={cave?.prix_jour_min} displayType={'text'} thousandSeparator={' '} suffix={' FCFA'} /></span>
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Pour une nuitée</span>
              <span><NumberFormat defaultValue={0} value={cave?.prix_nuit_min} displayType={'text'} thousandSeparator={' '} suffix={' FCFA'} /></span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderReserveTable = () => {
    return (
      <Transition appear show={isOpenModalReserveTable} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          unmount={false}
          onClose={() => { }}
        >
          <div className="px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full">
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-auto">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-left">
                    <h3
                      className="text-lg lg:text-2xl font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Reservation de la table "{table?.designation}"
                    </h3>
                    <span className="absolute right-3 top-3">
                      <ButtonClose onClick={closeModalReserveTable} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    <div className="space-y-4 py-5">
                      <div className="flex items-center flex-wrap justify-between pb-5">
                        <h2 className="text-lg font-semibold my-2">Reservation</h2>
                        <h2 className="text-lg font-semibold my-2">Total : <NumberFormat defaultValue={0} value={prixTotalTable} displayType={'text'} thousandSeparator={' '} suffix={' FCFA'} /></h2>
                      </div>
                      <div className="space-y-4 py-5 pt-0">

                        <div className="w-full">
                          <RadioGroup value={periodiciteTable} onChange={setPeriodiciteTable}>
                            <RadioGroup.Label className="text-gray-600 font-semibold">Reservation pour</RadioGroup.Label>
                            <div className="grid grid-cols-1 gap-4 md:grid-cols-2" style={{ marginTop: '1rem' }}>
                              <RadioGroup.Option
                                value="DEMI-JOUR"
                                className={({ active, checked }) => (
                                  `border border-neutral-100 ${checked ? 'bg-gray-400' : 'bg-white'} rounded-lg p-4 cursor-pointer overflow-hidden hover:shadow-xl transition-shadow`
                                )}
                              >
                                {({ active, checked }) => (
                                  <React.Fragment>
                                    <div className="flex items-center justify-between w-full">
                                      <div className="space-y-2">
                                        <RadioGroup.Label
                                          as="p"
                                          className={`text-xl ${checked ? 'text-white' : 'text-gray-600'}`}
                                        >
                                          La démi-journée
                                        </RadioGroup.Label>

                                        <RadioGroup.Description
                                          as="p"
                                          className={`text-sm ${checked ? 'text-white' : 'text-gray-400'}`}
                                        >
                                          <NumberFormat defaultValue={0} value={table?.prix_jour} displayType={'text'} thousandSeparator={' '} suffix={' FCFA'} /> la démi-journée
                                        </RadioGroup.Description>
                                      </div>
                                      {checked && (
                                        <div className="flex-shrink-0 ">
                                          <CheckIcon className="w-6 h-6" />
                                        </div>
                                      )}
                                    </div>
                                  </React.Fragment>
                                )}
                              </RadioGroup.Option>

                              <RadioGroup.Option
                                value="NUITEE"
                                className={({ active, checked }) => (
                                  `border border-neutral-100 ${checked ? 'bg-gray-400' : 'bg-white'} rounded-lg p-4 cursor-pointer overflow-hidden hover:shadow-xl transition-shadow`
                                )}
                              >
                                {({ active, checked }) => (
                                  <React.Fragment>
                                    <div className="flex items-center justify-between w-full">
                                      <div className="space-y-2">
                                        <RadioGroup.Label
                                          as="p"
                                          className={`text-xl ${checked ? 'text-white' : 'text-gray-600'}`}
                                        >
                                          La nuitée
                                        </RadioGroup.Label>

                                        <RadioGroup.Description
                                          as="p"
                                          className={`text-sm ${checked ? 'text-white' : 'text-gray-400'}`}
                                        >
                                          <NumberFormat defaultValue={0} value={table?.prix_nuit} displayType={'text'} thousandSeparator={' '} suffix={' FCFA'} /> la nuitée
                                        </RadioGroup.Description>
                                      </div>
                                      {checked && (
                                        <div className="flex-shrink-0 ">
                                          <CheckIcon className="w-6 h-6" />
                                        </div>
                                      )}
                                    </div>
                                  </React.Fragment>
                                )}
                              </RadioGroup.Option>
                            </div>
                          </RadioGroup>
                        </div>

                        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">

                          {/* <FormItem
                            label="Reservation pour"
                            className="block"
                          >
                            <Select
                              defaultValue={periodiciteTable}
                              placeholder="Reservation pour"
                              rounded="rounded-lg"
                              className="mt-1"
                              onChange={(e) => setPeriodiciteTable(e.target.value)}
                            >
                              <option value="DEMI-JOUR">la démi-journée</option>
                              <option value="NUITEE">la nuitée</option>
                            </Select>
                          </FormItem> */}

                          <FormItem
                            label="Date de la reservation"
                            className="block"
                            required
                          >
                            <DatePicker
                              defaultValue={selectedDateTable}
                              rounded="rounded-lg"
                              onChange={(date) => setSelectedDateTable(date)}
                              showTimeSelect={true}
                              dateFormat="dd/MM/yyyy HH:mm:ss"
                            />
                          </FormItem>

                          {periodiciteTable === 'NUITEE' &&
                            <FormItem
                              label="Durée"
                              className="block"
                            >
                              <Input
                                defaultValue={dureeTable}
                                type="number"
                                placeholder="Durée"
                                rounded="rounded-lg"
                                className="mt-1"
                                onChange={(e) => setDureeTable(+e.target.value)}
                              />
                            </FormItem>
                          }
                        </div>
                      </div>
                    </div>

                    <div className="space-y-4 py-5">
                      <div className="flex items-center flex-wrap justify-between">
                        <h2 className="text-lg font-semibold">Commande</h2>
                        <h2 className="text-lg font-semibold">Total : <NumberFormat defaultValue={0} value={prixTotalCommande} displayType={'text'} thousandSeparator={' '} suffix={' FCFA'} /></h2>
                      </div>
                      <div className="space-y-4 py-5 pt-0">
                        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                          <FormItem
                            label="Boisson"
                            className="block"
                          >
                            <Select
                              defaultValue=""
                              placeholder="Boisson"
                              rounded="rounded-lg"
                              className="mt-1"
                              onChange={(e) => handleBoissonChange(e.target.value)}
                            >
                              <option value=''>Choisir une boisson</option>
                              {cave?.boissons?.map((item: any, index: any) => (
                                <option key={index} value={index}>{item?.boisson?.libelle} - {item?.quantite}</option>
                              ))}
                            </Select>
                          </FormItem>

                          <FormItem
                            label="Quantité"
                            className="block"
                          >
                            <Input
                              defaultValue={quantite}
                              type="number"
                              placeholder="Quantité"
                              rounded="rounded-lg"
                              className="mt-1"
                              onChange={(e) => setQuantite(+e.target.value)}
                            />
                          </FormItem>
                          <div className="flex justify-center items-end">
                            <ButtonPrimary sizeClass="px-4 py-4 sm:px-6" rounded="rounded-lg" fontSize="text-xs" className="w-full" onClick={() => handleAddBoissonCommande()}>Ajouter</ButtonPrimary>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col space-y-4 py-5 pt-0">
                        {commande?.map((item: any, index: any) => (
                          <div key={index} className={`flex items-center justify-between text-neutral-6000 dark:text-neutral-300 space-y-2 py-2 ${index % 2 === 0 ? ' border-b border-neutral-200' : ''}`}>
                            <div className="flex items-center">
                              <button
                                className="mr-4 w-8 h-8 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
                                type="button"
                                onClick={() => handleRemoveBoissonCommande(index)}
                              >
                                <TrashIcon className="w-4 h-4" />
                              </button>
                              <span>
                                <NcInputNumber
                                  className="w-full"
                                  defaultValue={item?.quantite}
                                  onChange={(value) => handleUpdateBoissonCommande(value, index)}
                                  max={item?.boisson?.quantite}
                                  min={1}
                                  label={item?.boisson?.boisson?.libelle}
                                  desc={`${item?.boisson?.prix_event ? item?.boisson?.prix_event : item?.boisson?.prix} FCFA`}
                                />
                              </span>
                            </div>
                            <span><NumberFormat defaultValue={0} value={item?.prix} displayType={'text'} thousandSeparator={' '} suffix={' FCFA'} /></span>
                          </div>
                        ))}
                        {/* <div className="flex justify-between font-semibold">
                          <span>Total</span>
                          <span>{prixTotalCommande} FCFA</span>
                        </div> */}
                      </div>
                    </div>

                    <div className="py-5">
                      <div className="flex justify-center items-center py-5">
                        <p className="font-semibold text-3xl space-y-0 sm:space-y-5"><i className="las la-receipt mr-2"></i>
                          <NumberFormat defaultValue={0} value={prixTotalTable >= prixTotalCommande ? prixTotalTable : prixTotalCommande} displayType={'text'} thousandSeparator={' '} suffix={' FCFA'} /></p>
                      </div>

                      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 pt-5">
                        <div className="flex justify-center">
                          {cave?.site?.reglement && (
                            <div className="flex justify-center">
                              <CheckBox
                                name="reglement-interieur"
                                defaultChecked={isAcceptReserveTable}
                                onCheck={(value) => setIsAcceptReserveTable(value)}
                                label="J'accepte le règlement intérieur"
                                url={cave?.site?.reglement_path_public}
                              />
                            </div>
                          )}
                        </div>

                        <ButtonPrimary
                          rounded="rounded-lg"
                          fontSize="text-xs"
                          onClick={() => handleReserveTable()}
                          loading={checkReserveDateTable}
                          disabled={!isAcceptReserveTable || isPossibleReserveTable}
                        >
                          {reservationButtonTextTable}
                        </ButtonPrimary>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog >
      </Transition >
    );
  };

  const renderSectionCheckIndate = () => {
    return (
      <div className="listingSection__wrap overflow-hidden">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Disponibilités de la cave</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}

        <div className="listingSection__wrap__DayPickerRangeController flow-root">
          <div className="-mx-4 sm:mx-auto xl:mx-[-22px]">
            <DateDisponible
              size={windowSize.width < 1280 ? 1 : 2}
              daySize={getDaySize()}
              dates={reservations}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    if (isConnected) {
      return (
        <div className="listingSection__wrap shadow-2xl border-0" >

          <h2 className="text-2xl font-semibold">Reserver la cave </h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

          {/* FORM */}
          <form className="flex flex-col " >
            <div className="flex-1">
              <PeriodiciteInput
                defaultValue={periodicite}
                onChange={(periode) => setPeriodicite(periode)}
                fieldClassName="p-2 border border-neutral-200 dark:border-neutral-700"
              />
            </div>
            <div className="flex-1">
              <MeubleDateSingleInput
                defaultValue={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                showTimeSelect={true}
                dateFormat="dd/MM/yyyy HH:mm:ss"
                displayFormat="LLL"
                fieldClassName="p-2 border border-neutral-200 dark:border-neutral-700"
                className="mt-2"
              />
            </div>
            {periodicite === 'NUITEE' &&
              <div className="flex-1">
                <DureInput
                  defaultValue={duree}
                  onChange={(duree) => setDuree(duree)}
                  className="mt-2"
                  fieldClassName="p-2 border border-neutral-200 dark:border-neutral-700"
                />
              </div>
            }
          </form >

          {/* SUM */}
          < div className="flex flex-col space-y-4" >
            <div className="flex justify-center font-semibold text-3xl">
              <span><i className="las la-receipt mr-2"></i>
                <NumberFormat defaultValue={0} value={prixTotal ? prixTotal : 0} displayType={'text'} thousandSeparator={' '} suffix={' FCFA'} />
              </span>
            </div>
          </div >

          {cave?.site?.reglement && (
            <div className="flex justify-center">
              <CheckBox
                name="reglement-interieur"
                defaultChecked={isAcceptReserve}
                onCheck={(value) => setIsAcceptReserve(value)}
                label="J'accepte le règlement intérieur"
                url={cave?.site?.reglement_path_public}
              />
            </div>
          )}

          {/* SUBMIT */}
          <ButtonPrimary onClick={() => handleReserveCave()} loading={checkReserveDateCave} disabled={!isAcceptReserve || isPossibleReserveCave}>
            {reservationButtonTextCave}
          </ButtonPrimary>
        </div >
      )
    }

    return (
      <div className="listingSection__wrap shadow-2xl border-0 text-center">
        <h2 className="text-xl font-semibold">Connectez-vous pour reserver la cave </h2>
        < ButtonPrimary href='/login' className="mt-4">Se connecter</ButtonPrimary >
      </div >
    )
  };

  return (
    <div
      className={`nc-ListingCaveDetailPage  ${className}`}
      data-nc-id="ListingCaveDetailPage"
    >
      <Helmet>
        <title>{cave?.nom ?? ''} - LMDC Lounge</title>
      </Helmet>

      <React.Fragment>
        <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
            <div
              className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={() => cave?.image_principale && handleOpenModal(images?.findIndex(elt => elt === firstImage))}
            >
              <NcImage
                containerClassName="aspect-w-4 aspect-h-3 h-full"
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                src={firstImage}
                prevImageHorizontal
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            {othersImages?.filter((_, i) => i < 4).map((item, index) => (
              <div
                key={index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 2 ? "hidden sm:block" : ""
                  }`}
              >
                <NcImage
                  containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl "
                  src={item || ""}
                  prevImageHorizontal
                />

                <div
                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                  onClick={() => handleOpenModal(images?.findIndex(elt => elt === item))}
                />
              </div>
            ))}

            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Voir toute les photos
              </span>
            </div>
          </div>
        </header>

        <ModalPhotos
          imgs={images}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
        />
      </React.Fragment>

      <main className="container mt-11 flex ">
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:pr-10 lg:space-y-10">
          {renderSection1()}
          {cave?.description && renderSection2()}
          {renderSection5()}
          {cave?.tables?.length > 0 && renderSection4()}
          {cave?.boissons?.length > 0 && renderSection3()}
          {renderSectionCheckIndate()}
        </div>

        <div className="hidden lg:block flex-grow">
          <div className="sticky top-24">{renderSidebar()}</div>
        </div>
      </main>

      <div className="block lg:hidden fixed bottom-0 inset-x-0 py-4 bg-white text-neutral-900 border-t border-neutral-200 z-20">
        <div className="container flex items-center justify-between">
          <span className="text-2xl font-semibold">
            $311
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /person
            </span>
          </span>

          <ButtonPrimary href="##">Reserve</ButtonPrimary>
        </div>
      </div>

      <div className="container py-24 lg:py-32">
        <SectionSubscribe2 className="pt-24 lg:pt-32" />
      </div>
    </div>
  );
};

const CheckIcon = (props: any) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.4" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ListingCaveDetailPage;
