import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import userReducer from './user/reducer';
import authReducer from './auth/reducer';
import loaderReducer from './loader/reducer';
import siteReducer from './site/reducer';
import bienReducer from './bien/reducer';
import caveReducer from './cave/reducer';
import meubleReducer from './meuble/reducer';

export const store = configureStore({
    reducer: {
        user: userReducer,
        auth: authReducer,
        loader: loaderReducer,
        site: siteReducer,
        bien: bienReducer,
        cave: caveReducer,
        meuble: meubleReducer
    },
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;