import http from '../utils//http-client';
import { store } from "core/store";
import { setSites, setSitesPopular } from 'core/store/site/reducer';
import { start, stop } from 'core/store/loader/reducer';
// import { setBiens } from 'core/store/bien/reducer';

const source = 'site'

class SiteService {
    all() {
        store.dispatch(start());
        return new Promise(async (resolve, reject) => {
            try {
                if (store.getState().site.data?.length <= 0) {
                    const rs = await http.get(source);
                    store.dispatch(setSites(rs?.data?.data));
                    store.dispatch(stop());
                    resolve(rs?.data?.data);
                } else {
                    store.dispatch(stop());
                    resolve(store.getState().site.data);
                }
            } catch (error) {
                store.dispatch(stop());
                reject(error);
            }
        });
    }

    popular() {
        return new Promise(async (resolve, reject) => {
            try {
                if (store.getState().site.popular?.length <= 0) {
                    const rs = await http.get(`${source}/popular`);
                    store.dispatch(setSitesPopular(rs?.data?.data));
                    resolve(rs?.data?.data);
                } else {
                    resolve(store.getState().site.popular);
                }
            } catch (error) {
                reject(error);
            }
        });
    }

    one(id: any) {
        return new Promise(async (resolve, reject) => {
            try {
                let site = null;
                if (store.getState().site.data?.length <= 0) {
                    const rs: any = await this.all();
                    site = rs?.find((site: any) => site?.id === +id);
                } else {
                    store.dispatch(start());
                    site = store.getState().site.data?.find((site: any) => site?.id === +id);
                }
                store.dispatch(stop());
                resolve(site);
            } catch (error) {
                store.dispatch(stop());
                reject(error);
            }
        });
    }
}

export default new SiteService();