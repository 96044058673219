import React, { FC, ReactNode } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Header from "./Header";
import StayCard from "components/StayCard/StayCard";

export interface SectionGridFeaturePropertyProps {
  data?: any[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const SectionGridFeatureProperty: FC<SectionGridFeaturePropertyProps> = ({
  data = [],
  gridClass = "",
  heading = "Sites recommandés",
  subHeading = "Sites populaires que nous recommandons",
  headingIsCenter,
  tabs = [],
}) => {
  const renderCard = (stay: any, index: number) => {
    if (stay?.immeubles_count > 0) {
      return <StayCard key={index} className="h-full" data={stay} />;
    }
  };

  return (
    <div className="nc-SectionGridFeatureProperty relative">
      <Header
        subHeading={subHeading}
        heading={heading}
      />
      <div
        className={`grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-1 xl:grid-cols-3 ${gridClass}`}
      >
        {data.map(renderCard)}
      </div>
      <div className="flex mt-16 justify-center items-center">
        <ButtonPrimary href="/sites" className="!leading-none">
          <span>Voir tous les sites</span>
          <i className="ml-3 las la-arrow-right text-xl"></i>
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default SectionGridFeatureProperty;
