import React, { FC, Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import DureInput from "components/HeroSearchForm/DureInput";
import CheckBox from "shared/Checkbox/Checkbox";
import PeriodiciteInput from "components/HeroSearchForm/PeriodiciteInput";
import MeubleDateSingleInput from "components/HeroSearchForm/MeubleDateSingleInput";
import DateDisponible from "components/DateDisponible/DateDisponible";
import useWindowSize from "hooks/useWindowResize";
import moment from "moment";
import { Helmet } from "react-helmet";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcImage from "shared/NcImage/NcImage";
import ModalPhotos from "./ModalPhotos";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import { useParams } from "react-router-dom";

import meubleService from "core/services/meuble.service";
import siteService from "core/services/site.service";
import bienService from "core/services/bien.service";
import payService from "core/services/payment.service";
import mailService from "core/services/mail.service";

import Alert from '../../core/utils/alert'
import { useAppSelector } from '../../core/store/index';
import placeholder from '../../images/meuble-placeholder.png';

import NumberFormat from 'react-number-format';

export interface ListingMeubleDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
}

const ListingMeubleDetailPage: FC<ListingMeubleDetailPageProps> = ({
  className = "",
  isPreviewMode,
}) => {
  let [isOpenModalReserve, setIsOpenModalReserve] = useState(false);

  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);

  const user = useAppSelector(state => state.user.data);
  const isConnected = useAppSelector(state => state.auth.connected);
  const [meuble, setMeuble] = useState<any>(null);
  const [immeuble, setImmeuble] = useState<any>(null);
  const [site, setSite] = useState<any>(null);

  const [isAcceptReserve, setIsAcceptReserve] = useState(false);
  const [isPossibleReserve, setIsPossibleReserve] = useState(false);
  const [checkReserveDate, setCheckReserveDate] = useState(false);
  const [reservationButtonText, setReservationButtonText] = useState('Reserver');

  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);

  const [duree, setDuree] = useState(0);
  const [periodicite, setPeriodicite] = useState('DEMI-JOUR');
  const [statut, setStatut] = useState('TOTALITE');
  const [prixTotal, setPrixTotal] = useState(0);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const [images, setImages] = useState<string[]>([]);
  const [othersImages, setOthersImages] = useState<string[]>([]);
  const [firstImage, setFirstImage] = useState<any>(placeholder);

  const [caracteristiques, setCaracteristiques] = useState<any[]>([]);

  const [reservations, setReservations] = useState<any[]>([]);

  const windowSize = useWindowSize();

  const { site: siteId, immeuble: immeubleId, meuble: meubleId } = useParams<{ immeuble?: any, meuble?: any, site?: any }>();

  const loadMeuble = () => {
    meubleService.one(meubleId, immeubleId).then(
      (rs: any) => {
        setMeuble(rs);
        formImages(rs);
        formOthersImages(rs);
        setReservations(rs?.reservations_encours);
        if (rs?.image_principale) {
          setFirstImage(rs?.image_principale?.path);
        }
        setCaracteristiques(getCaracteristique(rs));
      }
    )
  };

  const loadSite = () => {
    siteService.one(siteId).then(
      (rs: any) => {
        setSite(rs);
        loadImmeuble();
      }
    )
  };

  const loadImmeuble = () => {
    bienService.one(immeubleId, siteId).then(
      (rs: any) => {
        setImmeuble(rs);
        if (!rs?.site?.reglement) {
          setIsAcceptReserve(true);
        }
        loadMeuble();
      }
    )
  };

  const formImages = (data: any) => {
    let result = data?.images?.map((elt: any) => {
      return elt?.path
    });
    if (result?.length <= 0) {
      result = [placeholder]
    }
    setImages(result);
  };

  const formOthersImages = (data: any) => {
    let result = data?.others_images?.map((elt: any) => {
      return elt?.path
    });
    setOthersImages(result);
  };

  const closeModalReserve = () => {
    setIsOpenModalReserve(false);
  };

  const openModalReserve = () => {
    setIsOpenModalReserve(true);
  };

  useEffect(() => {
    isDateAvailable();
  }, [selectedDate]);

  useEffect(() => {
    loadSite();
  }, [meubleId]);

  useEffect(() => {
    setPrixTotal(duree * meuble?.prix_nuit);
  }, [duree, meuble]);

  useEffect(() => {
    switch (periodicite) {
      case 'NUITEE':
        setPrixTotal(duree * meuble?.prix_nuit);
        break;
      default:
        setPrixTotal(meuble?.prix_jour);
        break;
    }
  }, [periodicite, meuble]);

  useEffect(() => {
    switch (periodicite) {
      case 'NUITEE':
        setPrixTotal(duree * meuble?.prix_nuit);
        break;
      default:
        setPrixTotal(meuble?.prix_jour);
        break;
    }
  }, [periodicite]);

  const getCaracteristique = (data: any) => {
    if (data?.caracteristiques?.length > 0) {
      return data?.caracteristiques?.map((elt: any) => {
        return {
          name: elt?.libelle,
          icon: "la-check-circle"
        }
      })
    }
    return [];
  };

  const getDaySize = () => {
    if (windowSize.width <= 375) {
      return 34;
    }
    if (windowSize.width <= 500) {
      return undefined;
    }
    if (windowSize.width <= 1280) {
      return 56;
    }
    return 48;
  };

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  };

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  };

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handlePayment = async (reservation?: any) => {
    const { value: numero } = await Alert.fire({
      title: 'Paiement de votre reservation',
      text: 'Entrez votre numéro de téléphone de votre compte mobile money',
      allowOutsideClick: false,
      allowEscapeKey: false,
      input: 'text',
      inputPlaceholder: 'Numéro de téléphone',
      inputValue: user?.telephone,
      showCancelButton: false,
      confirmButtonText: 'Payer',
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (!value) {
            resolve('Numéro de téléphone requis!')
          }
          resolve(null)
        })
      }
    });


    const data = {
      numero,
      montant: reservation.prix_total,
      message: `votre réservation ${reservation.reference} sur la plateforme LMDC Lounge`
    };

    payService.pay(data).then(
      (rs: any) => {
        const data = {
          methode: (rs?.operator as string).toUpperCase(),
          trans_id: rs?.reference,
          numero
        };

        payService.save(reservation.id, data);

        Alert.fire(
          'Merci pour votre réservation!',
          `<p>Validez votre paiement en tapant ${rs?.ussd_code} sur votre téléphone.</p>
          <p>Veuillez nous contacter pour la validation de votre réservation</p>
          <p><strong>Téléphone : ${immeuble?.telephone}</strong></p>
          <p><strong>Email : ${immeuble?.email}</strong></p>`,
          'info'
        )
      },
      (error: any) => {
        console.error(error);

        Alert.fire({
          allowOutsideClick: false,
          allowEscapeKey: false,
          icon: 'error',
          title: 'Oops !! le paiement a échoué',
          text: 'Il se peut que votre compte ne soit pas suffisamment approvisionné pour traiter le paiement. Voulez-vous réessayer ?',
          footer: '<a href="mailto:someone@yoursite.com?subject=Besoin d\'une assistance technique">Contacter-nous pour une assistance technique</a>',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: 'Réessayer',
          denyButtonText: 'Annuler',
        }).then(async (result) => {
          if (result.isConfirmed) {
            handlePayment(reservation);
          }
        });
      }
    );
  };

  const handleCloseModal = () => setIsOpen(false);

  const isDateAvailable = () => {

    if (!selectedDate) {
      return;
    }

    setCheckReserveDate(true);

    setReservationButtonText('Verification de la date');

    meubleService.check(meubleId, { date_debut: moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'), periode: periodicite }).then(
      (rs: any) => {
        setCheckReserveDate(false);
        setIsPossibleReserve(!rs.data.free);
        setReservationButtonText('Reserver');
        if (!rs.data.free) {
          Alert.fire({
            title: 'Vérification de la disponibilité!',
            text: rs.message,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'Changer de date'
          });
          setReservationButtonText('Changer la date');
        }
      },
      (error: any) => {
        setCheckReserveDate(false);
      }
    )
  };

  const initReservation = () => {
    setDuree(0);
    setPrixTotal(meuble?.prix_jour);
    setPeriodicite('DEMI-JOUR');
    setStatut('TOTALITE');
    setSelectedDate(null);
  }

  const handleReserve = () => {
    if (periodicite === 'NUITEE' && duree <= 0) {
      Alert.fire({
        title: 'Attention!',
        text: 'Quelle est la durée de votre séjour ?',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Remplir'
      });
      return;
    }

    if (!selectedDate) {
      Alert.fire({
        title: 'Attention!',
        text: 'Quand occuperez-vous le meublé ?',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Remplir'
      });
      return;
    }

    const data = {
      duree: duree,
      periodicite: periodicite,
      prix_total: prixTotal,
      etat: 'IMPAYER',
      type: 'MEUBLE',
      user_id: user?.id,
      staut: statut,
      date_debut: moment(selectedDate)?.format('YYYY-MM-DD HH:mm:ss'),
      bien_immobilier_id: immeubleId
    }

    meubleService.reserver(meubleId, data).then(
      (rs: any) => {
        initReservation();
        mailService.reservation(rs.data?.data?.reservation?.id);
        Alert.fire({
          title: 'Réservation effectuée!',
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: rs.data.message,
          icon: 'success',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: 'Payer en ligne',
          denyButtonText: 'Payer en espèce',
        }).then(async (result) => {
          if (result.isConfirmed) {
            handlePayment(rs.data?.data?.reservation);
          } else if (result.isDenied) {
            Alert.fire('Merci pour votre réservation!', `<p>Veuillez nous contacter pour la validation de votre réservation</p> <p><strong>Téléphone : ${immeuble?.telephone}</strong></p> <p><strong>Email : ${immeuble?.email}</strong></p>`, 'info')
          }
        });
        setReservations(rs.data?.data?.reservation_encours);
      }
    )
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">

        <div className="flex justify-between items-center">
          <Badge name={meuble?.type_complet} />
          {/* <LikeSaveBtns /> */}
        </div>

        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {meuble?.designation}
        </h2>

        <div className="flex flex-wrap">

          <div className="flex items-center space-x-2" style={{ paddingRight: '2.5rem' }}>
            <i className="las la-home"></i>
            <span>{immeuble?.nom}</span>
          </div>

          <div className="flex items-center space-x-2" style={{ paddingRight: '2.5rem' }}>
            <i className="las la-map-marker-alt"></i>
            <span>{site?.adresse}, {immeuble?.adresse}</span>
          </div>

        </div >

        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        <div className="flex flex-wrap text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-2 my-2" style={{ paddingRight: '2.5rem' }}>
            <i className="las la-bed text-2xl"></i>
            <span>
              {meuble?.chambre} <span className="hidden sm:inline-block">chambre(s)</span>
            </span>
          </div>
          <div className="flex items-center space-x-2 my-2" style={{ paddingRight: '2.5rem' }}>
            <i className="las la-bath text-2xl"></i>
            <span>
              {meuble?.toilet} <span className="hidden sm:inline-block">toilette(s)</span>
            </span>
          </div>
          <div className="flex items-center space-x-2 my-2">
            <i className="las la-expand-arrows-alt text-2xl"></i>
            <span>
              {meuble?.superficie} m<sup>2</sup>
            </span>
          </div>
        </div>
      </div >
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Description</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300" dangerouslySetInnerHTML={{ __html: meuble?.description }}></div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Caractéristiques </h2>

        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {caracteristiques?.filter((_, i) => i < 12).map((item) => (
            <div key={item.name} className="flex items-center space-x-3">
              <i className={`text-3xl las ${item.icon}`}></i>
              <span className=" ">{item.name}</span>
            </div>
          ))}
        </div>

        <div className="w-14 border-b border-neutral-200"></div>
        <div>
          <ButtonSecondary onClick={openModalAmenities}>
            Voir toute les caractéristiques
          </ButtonSecondary>
        </div>
        {renderMotalAmenities()}
      </div>
    );
  };

  const renderMotalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full">
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Caractéristiques
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {caracteristiques?.map((item) => (
                      <div
                        key={item.name}
                        className="flex items-center py-6 space-x-8"
                      >
                        <i
                          className={`text-4xl text-neutral-6000 las ${item.icon}`}
                        ></i>
                        <span>{item.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSection4 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Prix des reservations </h2>

        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div className="flow-root mb-16">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 mb-4">
            <div className="p-4  flex justify-between items-center space-x-4 rounded-lg">
              <span>Pour une démi-jour</span>
              <span><NumberFormat defaultValue={0} value={meuble?.prix_jour} displayType={'text'} thousandSeparator={' '} suffix={' FCFA'} /></span>
            </div>

            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Pour une nuitée</span>
              <span><NumberFormat defaultValue={0} value={meuble?.prix_nuit} displayType={'text'} thousandSeparator={' '} suffix={' FCFA'} /></span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">

        <h2 className="text-2xl font-semibold">Prix minimal des reservations </h2>

        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div className="flow-root mb-16">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 mb-4">
            <div className="p-4  flex justify-between items-center space-x-4 rounded-lg">
              <span>Pour une démi-jour</span>
              <span><NumberFormat defaultValue={0} value={meuble?.prix_jour_min} displayType={'text'} thousandSeparator={' '} suffix={' FCFA'} /></span>
            </div>

            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Pour une nuitée</span>
              <span><NumberFormat defaultValue={0} value={meuble?.prix_nuit_min} displayType={'text'} thousandSeparator={' '} suffix={' FCFA'} /></span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSectionCheckIndate = () => {
    return (
      <div className="listingSection__wrap overflow-hidden">

        <h2 className="text-2xl font-semibold">Disponibilités du meublé</h2>

        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div className="listingSection__wrap__DayPickerRangeController flow-root">
          <div className="-mx-4 sm:mx-auto xl:mx-[-22px]">
            <DateDisponible
              size={windowSize.width < 1280 ? 1 : 2}
              daySize={getDaySize()}
              dates={reservations}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    if (isConnected) {
      return (
        <div className="listingSection__wrap shadow-xl">

          <h2 className="text-2xl font-semibold">Reserver le meublé </h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

          <div className="flex flex-col">
            <PeriodiciteInput
              defaultValue={periodicite}
              onChange={(periode) => setPeriodicite(periode)}
              className="mt-2"
              fieldClassName="p-2 border border-neutral-200 dark:border-neutral-700"
            />

            <MeubleDateSingleInput
              defaultValue={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              showTimeSelect={true}
              dateFormat="dd/MM/yyyy HH:mm:ss"
              displayFormat="LLL"
              fieldClassName="p-2 border border-neutral-200 dark:border-neutral-700"
              className="mt-2"
            />

            {periodicite === 'NUITEE' &&
              <DureInput
                defaultValue={duree}
                onChange={(duree) => setDuree(duree)}
                className="mt-2"
                fieldClassName="p-2 border border-neutral-200 dark:border-neutral-700"
              />
            }
          </div>

          <div className="flex flex-col space-y-4">
            <div className="flex justify-center font-semibold text-3xl">
              <span><i className="las la-receipt mr-2"></i> <NumberFormat defaultValue={0} value={prixTotal} displayType={'text'} thousandSeparator={' '} suffix={' FCFA'} /></span>
            </div>
          </div>

          {immeuble?.site?.reglement && (
            <div className="flex justify-center">
              <CheckBox
                name="reglement-interieur"
                defaultChecked={isAcceptReserve}
                onCheck={(value) => setIsAcceptReserve(value)}
                label="J'accepte le règlement intérieur"
                url={immeuble?.site?.reglement_path_public}
              />
            </div>
          )}

          <ButtonPrimary onClick={() => handleReserve()} loading={checkReserveDate} disabled={!isAcceptReserve || isPossibleReserve}>
            {reservationButtonText}
          </ButtonPrimary>
        </div>
      );
    }

    return (
      <div className="listingSection__wrap shadow-2xl border-0 text-center">
        <h2 className="text-xl font-semibold">Connectez-vous pour reserver le meublé </h2>
        < ButtonPrimary href='/login' className="mt-4">Se connecter</ButtonPrimary >
      </div>
    )
  };

  const renderReserver = () => {
    return (
      <Transition appear show={isOpenModalReserve} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          unmount={false}
          onClose={() => { }}
        >
          <div className="px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full">
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-auto">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-left">
                    <h3
                      className="text-lg lg:text-2xl font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Reservation du meublé
                    </h3>
                    <span className="absolute right-3 top-3">
                      <ButtonClose onClick={closeModalReserve} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    <div className="space-y-4 py-5">
                      <div className="flex flex-col sm:flex-row justify-between items-center">
                        <p className="font-semibold text-2xl space-y-0 sm:space-y-5"><i className="las la-receipt mr-2"></i> {prixTotal} FCFA</p>

                        <ButtonPrimary
                          rounded="rounded-lg"
                          className="w-1/4 sm:w-2/4"
                          fontSize="text-xs"
                          onClick={() => handleReserve()}
                          loading={checkReserveDate}
                        >
                          {reservationButtonText}
                        </ButtonPrimary>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog >
      </Transition >
    );
  };

  return (
    <div
      className={`nc-ListingMeubleDetailPage  ${className}`}
      data-nc-id="ListingMeubleDetailPage"
    >
      <Helmet>
        <title>{meuble?.designation ?? ''} - LMDC Lounge</title>
      </Helmet>

      <React.Fragment>
        <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
            <div
              className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={() => meuble?.image_principale && handleOpenModal(images?.findIndex(elt => elt === firstImage))}
            >
              <NcImage
                containerClassName="aspect-w-4 aspect-h-3 h-full"
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                src={firstImage}
                prevImageHorizontal
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            {othersImages?.filter((_, i) => i < 5).map((item, index) => (
              <div
                key={index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 2 ? "hidden sm:block" : ""
                  }`}
              >
                <NcImage
                  containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl "
                  src={item || ""}
                  prevImageHorizontal
                />

                <div
                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                  onClick={() => handleOpenModal(images?.findIndex(elt => elt === item))}
                />
              </div>
            ))}

            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Voir toute les photos
              </span>
            </div>
          </div>
        </header>

        <ModalPhotos
          imgs={images}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
        />
      </React.Fragment>

      <main className="container mt-11 flex ">
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderSection1()}
          {meuble?.description && renderSection2()}
          {meuble?.caracteristiques?.length > 0 && renderSection3()}
          {renderSection4()}
          {renderSection5()}
          {renderSectionCheckIndate()}
        </div>

        <div className="hidden lg:block flex-grow">
          <div className="sticky top-24">{renderSidebar()}</div>
        </div>
      </main>

      {!isPreviewMode && (
        <React.Fragment>
          <div className="block lg:hidden fixed bottom-0 inset-x-0 py-4 bg-white text-neutral-900 border-t border-neutral-200 z-20">
            <div className="container flex items-center justify-center">
              <ButtonPrimary className="w-full" onClick={openModalReserve}>Reserver</ButtonPrimary>
            </div>
          </div>
          {renderReserver()}
        </React.Fragment>
      )}

      {/* OTHER SECTION */}
      {
        !isPreviewMode && (
          <div className="container py-24 md:pb-32">

            {/* SECTION */}
            <SectionSubscribe2 />
          </div>
        )
      }
    </div>
  );
};

export default ListingMeubleDetailPage;
