import Button, { ButtonProps } from "shared/Button/Button";
import React from "react";

export interface ButtonErrorProps extends ButtonProps {}

const ButtonError: React.FC<ButtonErrorProps> = ({
  className = "",
  ...args
}) => {
  return (
    <Button
      className={`ttnc-ButtonError disabled:bg-opacity-70 bg-red-500 hover:bg-red-400 text-neutral-50 ${className}`}
      {...args}
    />
  );
};

export default ButtonError;
