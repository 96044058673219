import React from "react";

import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'

const StayCardSkeleton = () => {

    const renderContent = () => {
        return (
            <div className="p-4 space-y-4">
                <div className="space-y-2">

                    <Skeleton height={200} />

                    <div className="flex items-center justify-between text-sm space-x-2">
                        <Skeleton width={150} />
                        <Skeleton width={150} />
                    </div>

                    <div className="space-x-2 py-2">
                        <Skeleton count={2} />
                    </div>

                    <div className="text-sm space-x-2">
                        <Skeleton />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div
            className={`nc-StayCardSkeleton group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow`}
            data-nc-id="StayCardSkeleton"
        >
            {renderContent()}
        </div>
    );
};

export default StayCardSkeleton;