import React, { useEffect, useState } from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionPresentation from "components/SectionPresentation/SectionPresentation";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import { Helmet } from "react-helmet";
import SectionHero2 from "components/SectionHero2/SectionHero2";

import HIW1img from "images/HIW2-1.png";
import HIW2img from "images/HIW2-2.png";
import HIW3img from "images/HIW2-3.png";
import HIW1imgDark from "images/HIW2-1-dark.png";
import HIW2imgDark from "images/HIW2-2-dark.png";
import HIW3imgDark from "images/HIW2-3-dark.png";
import caveImgJpg from "images/LMC1.jpeg";
import meubleImgJpg from "images/photo_meuble.jpg";

import SectionGridFeatureProperty from "./SectionGridFeatureProperty";

import siteService from "../../core/services/site.service";

function PageHome2() {

  const [popularSites, setPopularSites] = useState([]);

  const loadPopularSite = () => {
    siteService.popular().then(
      (rs: any) => {
        setPopularSites(rs);
      }
    )
  }

  useEffect(() => {
    loadPopularSite();
  }, []);

  return (
    <div className="nc-PageHome2 relative overflow-hidden">
      <Helmet>
        <title>LMDC Lounge</title>
      </Helmet>

      <SectionHero2 className="lg:mt-2" />

      <div className="container relative space-y-24 my-24 lg:space-y-32 lg:my-32">

        {/* Meuble */}
        <SectionPresentation className="lg:py-14" type="type2" head="Meublés" rightImg={meubleImgJpg} imgStyle={{ width: '70%', height: '70%' }}>
          <p className="block text-neutral-700 dark:text-neutral-300">
            Nous fournissons des logements pour des groupes de travailleurs. Notre vaste portefeuille d'immeubles va des appartements d'affaires modernes aux appartements économiques. Tous les appartements sont équipés du WiFi, de la télévision, de machines à laver et de sèche-linge, d'un service de ramassage des ordures, d'un parking à proximité et d'une assistance 24h/24.
          </p>
          <ButtonPrimary href='/sites'>Reserver un meublé</ButtonPrimary>
        </SectionPresentation>

        {/* SECTION2 */}
        <SectionPresentation type="type1" head="Caves" rightImg={caveImgJpg} imgStyle={{ width: '70%', height: '70%' }}>
          <p className="block text-neutral-700 dark:text-neutral-300">
            Nous nous approvisionnons en vins et liqueurs de qualité exceptionnelle, de style boutique, auprès de vignobles familiaux du monde entier. Notre assortiment est en constante évolution, avec plus de 500 vins et liqueurs disponibles pour un mélange et un assortiment sans fin. Nous sommes impatients de vous aider à découvrir un nouveau favori !
          </p>

          <ButtonPrimary href='/caves'>Reserver une cave</ButtonPrimary>
        </SectionPresentation>

        {/* SECTION */}
        {popularSites?.length > 0 && (
          <div className="relative py-16">
            <BackgroundSection />
            <SectionGridFeatureProperty data={popularSites} />
          </div>
        )}

        {/* SECTION */}
        <SectionHowItWork
          data={[
            {
              id: 1,
              img: HIW1img,
              imgDark: HIW1imgDark,
              title: "Meublés / Caves",
              desc: "Consultez meublés et caves.",
            },
            {
              id: 2,
              img: HIW2img,
              imgDark: HIW2imgDark,
              title: "Réserver",
              desc: "Consultez les détails d'un meublé ou d'une cave puis réservé.",
            },
            {
              id: 3,
              img: HIW3img,
              imgDark: HIW3imgDark,
              title: "Rendez-vous au site",
              desc: "Presentz-vous au site pour validez votre réservation.",
            },
          ]}
        />

        {/* SECTION */}
        <div className="relative">
          <BackgroundSection />
          <SectionSubscribe2 />
        </div>
      </div>
    </div>
  );
}

export default PageHome2;
