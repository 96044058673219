import rightImg from "images/kjh.jpg";
import React, { FC } from "react";
// import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
// import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
// import SectionClientSay from "components/SectionClientSay/SectionClientSay";

export interface PageAboutProps {
  className?: string;
}
const services2 = ["une résidence à louer ou à acheter", "un meublé à louer ou à achèter", "une villa a loué ou à acheter", "une cave a vain  ou consommé et acheté du vain pour nos événements", "des réservations de logements, véhicules, caves"];

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>A propos - LMDC Lounge</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading="👋 Qui sommes-nous ?"
          btnText=""
          subHeading="Une plateforme révolutionnaire qui joue le rôle d’intermédiaire entre le client et les besoins en Logements, location véhicules et Caves. Vous cherchez : "
          services={services2}
          endText='LMDC Lounge reste le meilleur choix à faire'
        />
        

        {/* <SectionFounder /> */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div> */}

        <SectionStatistic />

        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageAbout;
