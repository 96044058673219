import React, { FC } from "react";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import sitePlaceholder from "../../images/site-placeholder.png"

export interface StayCardProps {
  className?: string;
  data?: any;
  size?: "default" | "small";
}

const StayCard: FC<StayCardProps> = ({
  size = "default",
  className = "",
  data = null,
}) => {

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <NcImage src={data?.image_principale ? data?.image_principale?.path : sitePlaceholder} className="object-cover w-full" styleImg={{ height: '15rem' }} />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <div className="flex items-center space-x-2 py-5 pt-0">
            <h2
              className={` font-medium capitalize ${size === "default" ? "text-2xl" : "text-base"
                }`}
            >
              <span className="line-clamp-1">{data?.adresse}</span>
            </h2>
          </div>
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {data?.immeubles_count} immeuble(s)
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      <Link to={`sites/${data?.id}/immeubles`}>
        {renderSliderGallery()}
        {renderContent()}
      </Link>
    </div>
  );
};

export default StayCard;
