import React, { FC, ReactNode } from "react";
import {
  SearchTab,
} from "components/HeroSearchForm/HeroSearchForm";

export interface SectionHeroArchivePageProps {
  className?: string;
  listingType?: ReactNode;
  currentPage?: "Site" | "Bien" | "Meuble" | "Cave";
  currentTab?: SearchTab;
  head?: string,
  image?: string
  desc?: string,

}

const SectionHeroArchivePage: FC<SectionHeroArchivePageProps> = ({
  className = "",
  listingType,
  head = "",
  desc = "",
}) => {
  return (
    <div
      className={`nc-SectionHeroArchivePage flex flex-col relative ${className}`}
      data-nc-id="SectionHeroArchivePage"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex flex-col items-start space-y-6 pb-10">
          <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl leading-[110%]">
            {head}
          </h2>
          <div className="flex flex-wrap items-center text-base md:text-lg text-neutral-500 dark:text-neutral-400">
            {listingType && (
              listingType
            )}
          </div>
          {desc && (
            <p className="block text-base md:text-lg text-neutral-500 dark:text-neutral-400 text-justify mt-3"
              dangerouslySetInnerHTML={{ __html: desc }}></p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SectionHeroArchivePage;
