import React from "react";
import MyRouter from "routers/index";

// import { useAppSelector } from 'hooks/useAppSelector'
import { store } from "core/store";
import { Provider } from 'react-redux'

function App() {
  return (
    <Provider store={store}>
      <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
        <MyRouter />
      </div>

      <div className="fixed right-3 bottom-3 shadow-xl z-50 rounded-full" >
        <a href="https://wa.me/+237655449734?text=Bonjour LMDC Lounge" target="_blank">
          <img className="w-20 h-20" src="https://cdn2.iconfinder.com/data/icons/social-messaging-ui-color-shapes-2-free/128/social-whatsapp-circle-512.png" alt="Whatsapp" />
        </a>
      </div>
    </Provider>
  );
}

export default App;
