import React, { FC, InputHTMLAttributes, useState, useEffect } from "react";

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  subLabel?: string;
  className?: string;
  onCheck?: (value: boolean) => void;
  url?: string;
  name: string;
  defaultChecked?: boolean;
}

const Checkbox: FC<CheckboxProps> = ({
  subLabel = "",
  label = "",
  onCheck,
  name,
  className = "",
  defaultChecked = false,
  url = ""
}) => {
  const [value, setValue] = useState(defaultChecked);

  useEffect(() => {
    onCheck && onCheck(value);
  }, [value]);

  useEffect(() => {
    setValue(defaultChecked);
  }, [defaultChecked]);

  const handleCheck = (item: boolean) => {
    setValue(item);
  };

  return (
    <div className={`flex text-sm sm:text-base ${className}`}>
      <input
        id={name}
        name={name}
        type="checkbox"
        className="focus:ring-action-primary cursor-pointer h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
        defaultChecked={value}
        onChange={(e) => handleCheck(!value)}
      />
      {label && url ? (
        <a href={url}
          target="_blank"
          rel="noreferrer"
          className="hover:underline cursor-pointer ml-3.5 flex flex-col flex-1 justify-center"
        >
          <span className=" text-neutral-900 dark:text-neutral-100">
            {label}
          </span>
          {subLabel && (
            <p className="mt-1 text-neutral-500 dark:text-neutral-400 text-sm font-light">
              {subLabel}
            </p>
          )}
        </a>
      ) : (
        <label
          htmlFor={name}
          className="ml-3.5 flex flex-col flex-1 justify-center"
        >
          <span className=" text-neutral-900 dark:text-neutral-100">
            {label}
          </span>
          {subLabel && (
            <p className="mt-1 text-neutral-500 dark:text-neutral-400 text-sm font-light">
              {subLabel}
            </p>
          )}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
