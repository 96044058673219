import React, { FC, useMemo, useState } from "react";
import Pagination from "shared/Pagination/Pagination";
import Heading2 from "components/Heading/Heading2";
import PropertyCardH from "components/PropertyCardH/PropertyCardH";
import MeubleCardSkeleton from "components/SkeletonCard/MeubleCardSkeleton";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: any[];
  immeuble?: any;
  url?: string;
}

const PageSize = 8;

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data = [],
  immeuble = null,
  ...props
}) => {

  const { url } = props;
  const [currentPage, setCurrentPage] = useState(1);

  const currentData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return data?.length > 0 ? data?.slice(firstPageIndex, lastPageIndex) : [];
  }, [currentPage, data]);

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2
        heading={`Meublés de "${immeuble?.nom}"`}
        subHeading={
          <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
            {immeuble?.meubles_count > 0 ? immeuble?.meubles_count : 'Aucun'} meublé(s)
          </span>
        }
      />

      {data?.length <= 0 ? (
        <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2">
          {[1, 2].map((stay) => (
            <MeubleCardSkeleton key={stay} />
          ))}
        </div>
      ) : (
        <React.Fragment>
          <div className="grid grid-cols-1 gap-6 md:gap-8 xl:grid-cols-2 ">
            {currentData.map((stay) => (
              <PropertyCardH key={stay.id} data={stay} url={url} />
            ))}
          </div>
          <div className="flex mt-16 justify-center items-center">
            <Pagination
              currentPage={currentPage}
              totalCount={data?.length}
              pageSize={PageSize}
              onPageChange={page => setCurrentPage(page)}
            />
          </div>
        </React.Fragment>
      )}

    </div>
  );
};

export default SectionGridFilterCard;
