import http from '../utils//http-client';

const source = 'newsletter'

class NewsletterService {
    subcribe(email: any) {
        return new Promise(async (resolve, reject) => {
            try {
                const rs = await http.post(`subscribe/${source}`, { email });
                resolve(rs.data);
            } catch (error) {
                reject(error);
            }
        });
    }

    unsubcribe(email: any) {
        return new Promise(async (resolve, reject) => {
            try {
                const rs = await http.get(`${email}/unsubscribe/${source}`);
                resolve(rs.data);
            } catch (error) {
                reject(error);
            }
        });
    }
}

export default new NewsletterService();