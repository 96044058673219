import React, { FC } from "react";
import Heading from "shared/Heading/Heading";
// import ButtonSecondary from "shared/Button/ButtonSecondary";
import { ReactNode } from "react";

export interface HeaderProps {
  heading: ReactNode;
  subHeading?: ReactNode;
}

const Header: FC<HeaderProps> = ({
  subHeading = "",
  heading = "🎈 Latest Articles",
}) => {

  return (
    <div className="flex flex-col mb-8 relative">
      <Heading desc={subHeading}>{heading}</Heading>
      {/* <div className="flex items-center justify-between">
        <span className="hidden sm:block flex-shrink-0">
          <ButtonSecondary href="/sites" className="!leading-none">
            <span>Voir tous</span>
            <i className="ml-3 las la-arrow-right text-xl"></i>
          </ButtonSecondary>
        </span>
      </div> */}
    </div>
  );
};

export default Header;
