import { Link } from "react-router-dom";
import logoLightImg from "images/cave-logo.jpeg";
import React from "react";

export interface WidgetFooterMenu {
  id: string;
  title: string;
}

const Footer: React.FC = () => {
  return (
    <div className="nc-Footer relative py-6 lg:py-6 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container sm:flex sm:items-center sm:justify-between">
        <Link
          to="/"
          className={`flex items-center mb-4 sm:mb-0 justify-center lg:justify-start`}
        >
          <img
            className="mr-3 h-8 rounded-lg shadow-xl"
            src={logoLightImg}
            alt="LMDC Lounge"
          />
          <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">LMDC Lounge</span>
        </Link>
        <ul className="flex flex-wrap items-center justify-center lg:justify-start mb-6 text-gray-500 sm:mb-0 dark:text-gray-400">
          <li>
            <Link to="/about" className="mr-4 hover:underline md:mr-6 text-neutral-700">Qui sommes nous?</Link>
          </li>
          <li>
            <Link to="#" className="mr-4 hover:underline md:mr-6 text-neutral-700">Politique de confidentialité</Link>
          </li>
          <li>
            <Link to="#" className="mr-4 hover:underline md:mr-6 text-neutral-700">Licences</Link>
          </li>
          <li>
            <Link to="/contact" className="hover:underline text-neutral-700">Contact</Link>
          </li>
        </ul>
      </div>
      <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
      <span className="block text-gray-500 sm:text-center dark:text-gray-400 text-center">
        Copyright © 2022 <Link to="/" className="hover:underline">LMDC Lounge</Link>. Tous droits réservés.
      </span>
    </div>
  );
};

export default Footer;
