import React, { useState } from "react";
import { FC } from "react";
import { useEffect } from "react";
import { useRef } from "react";

export interface PeriodiciteInputProps {
  defaultValue?: string;
  onChange?: (value: string) => void;
  onInputDone?: (value: string) => void;
  className?: string;
  autoFocus?: boolean;
  fieldClassName?: string
}

const periodes = [
  { name: "La démi-journeée", value: 'DEMI-JOUR' },
  { name: "La nuitée", value: 'NUITEE' }
];

const PeriodiciteInput: FC<PeriodiciteInputProps> = ({
  defaultValue = 'DEMI-JOUR',
  autoFocus = false,
  onChange,
  onInputDone,
  className = "nc-flex-1.5",
  fieldClassName = "[ nc-hero-field-padding ]",
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState(defaultValue);
  const [inputValue, setInputValue] = useState(periodes.find(elt => elt.value === defaultValue)?.name);
  const [showPopover, setShowPopover] = useState(autoFocus);

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    setValue(defaultValue);
    setInputValue(periodes.find(elt => elt.value === defaultValue)?.name);
  }, [defaultValue]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [showPopover]);

  useEffect(() => {
    onChange && onChange(value);
  }, [value]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
  };

  const handleSelectLocation = (item: string) => {
    const val = periodes.find(elt => elt.value === item) ?? { name: "La démi-journeée", value: 'DEMI-JOUR' };
    setValue(val?.value);
    setInputValue(val?.name);
    onInputDone && onInputDone(item);
    setShowPopover(false);
  };

  const renderChoices = () => {
    return (
      <React.Fragment>
        <div className="mt-2">
          {periodes.map((item) => (
            <span
              onClick={() => handleSelectLocation(item.value)}
              key={item.value}
              className="flex px-3 sm:px-6 items-center space-x-3 sm:space-x-4 py-2 sm:py-3 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
            >
              <span className="block text-neutral-400">
              <i className="las la-chevron-circle-right la-1x"></i>
              </span>
              <span className=" block font-medium text-neutral-700 dark:text-neutral-200">
                {item.name}
              </span>
            </span>
          ))}
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`flex w-full relative ${fieldClassName} rounded-2xl flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left 
        ${showPopover ? "shadow-2xl" : ""
          }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <i className="las la-cloud-sun la-2x"></i>
        </div>
        <div className="flex-grow">
          <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
            <span className="line-clamp-1">{value ? 'Reservation pour ' : ''}</span>
          </span>
          <input
            className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
            placeholder="Reservation pour"
            readOnly
            value={inputValue}
            autoFocus={showPopover}
            ref={inputRef}
          />
        </div>
      </div>
      {showPopover && (
        <div className="absolute left-0 z-40 w-full min-w-[150px] sm:min-w-[300px] bg-white dark:bg-neutral-800 top-full mt-3 py-1 sm:py-3 rounded-3xl shadow-2xl max-h-96 overflow-y-auto">
          {renderChoices()}
        </div>
      )}
    </div>
  );
};

export default PeriodiciteInput;
