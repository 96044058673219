import React, { FC } from "react";

import LoadingOverlay from 'react-loading-overlay'

export interface LabelProps {
    active?: boolean;
    text?: string
}

const Loader: FC<LabelProps> = ({ active = false, children, text = 'Veuillez patienter...' }) => {
    return (
        <LoadingOverlay
            active={active}
            spinner
            text={text}
        >
            {children}
        </LoadingOverlay>
    )
}

export default Loader;