import { Tab } from "@headlessui/react";
// import CommentListing from "components/CommentListing/CommentListing";
import React, { FC, Fragment, useState, useEffect } from "react";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
// import ButtonSecondary from "shared/Button/ButtonSecondary";
import { Helmet } from "react-helmet";
import { useAppSelector } from '../../core/store/index';
import { FaPencilAlt } from 'react-icons/fa';
import { registerLocale } from "react-datepicker";
import moment from "moment";
import { Link } from "react-router-dom";
import userService from "core/services/user.service";

import 'moment/locale/fr'
import fr from 'date-fns/locale/fr';

registerLocale('fr', fr)

export interface AuthorPageProps {
  className?: string;
}

const AuthorPage: FC<AuthorPageProps> = ({ className = "" }) => {

  let [categories] = useState(["Meublés", "Caves", "Tables"]);
  const [reservations, setReservations] = useState([]);

  const user = useAppSelector(state => state.user.data);

  const handelLoadUser = () => {
    userService.findReservation(user?.id).then(
      (rs: any) => {
        setReservations(rs?.data);
      }
    );
  }

  useEffect(() => {
    handelLoadUser();
  }, [])

  const renderSidebar = () => {
    return (
      <div className=" authorP w-full flex flex-col items-center text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8">
        <Link className="modIcone" to="/accountP">
          <h3 className="text-2xl font-semibold"><FaPencilAlt /></h3>
        </Link>
        {user?.image ? (<Avatar imgUrl={user?.image?.path} sizeClass="w-32 h-32" />) : (<Avatar sizeClass="w-32 h-32" />)}
        <div className="space-y-3 text-center flex flex-col items-center">
          <h2 className="text-3xl font-semibold">{user?.nom_complet} </h2>
        </div>
        <div className="border-b border-neutral-200 dark:border-neutral-700 w-14"></div>

        {/* ---- */}
        <div className="space-y-4">
          {user?.cni && <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              CNI ({user?.cni})
            </span>
          </div>}

          <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              {user?.email}
            </span>
          </div>
          <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              {user?.telephone}
            </span>
          </div>

          <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              Rejoint le {moment(user?.created_at).locale('fr').format('LL')}
            </span>
          </div>

        </div>
      </div>
    );
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Vos reservations</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Tab.Group>
            <Tab.List className="flex space-x-1 overflow-x-auto">
              {categories.map((item) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${selected
                        ? "bg-secondary-900 text-secondary-50 "
                        : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                        } `}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {reservations?.filter((_: any) => _?.type === 'MEUBLE').filter((_, i) => i < 4).map((stay: any) => (
                    <div
                      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow`}
                      data-nc-id="StayCard"
                      key={stay.id}
                    >
                      <div className="p-4 space-y-4">
                        <div className="space-y-2">
                          <div className="flex items-center justify-between">
                            <Badge color="gray" name={`${stay?.reference}`} />

                            {stay?.etat === 'PAYER' && (
                              <Badge color="green" name={`Payée`} />
                            )}

                            {stay?.etat === 'IMPAYER' && (
                              <Badge color="red" name={`Non reglée`} />
                            )}

                            {stay?.etat === 'ANNULER' && (
                              <Badge color="gray" name={`Annulée`} />
                            )}
                          </div>

                          <div className="space-y-2 py-3">
                            <div>
                              <h2
                                className={`font-medium capitalize text-base`}
                              >
                                <span className="line-clamp-1">{stay?.elements?.meuble_bien?.type_complet} {stay?.elements?.meuble_bien?.designation}</span>
                              </h2>
                              <span className="text-sm text-neutral-500 dark:text-neutral-400 mb-5">
                                {stay?.elements?.meuble_bien?.immeuble?.adresse}, {stay?.site?.adresse}
                              </span>
                            </div>

                            <span className="text-xs text-neutral-500 dark:text-neutral-400">
                              Reserver le {moment(stay?.date_reservation).locale('fr').format('LL')} pour {stay?.elements?.duree > 1 ? stay?.elements?.duree : 'une'} {stay?.elements?.periodicite === 'DEMI-JOUR' && 'démi-journée(s)'} {stay?.elements?.periodicite === 'NUITEE' && 'nuitée(s)'}
                            </span>
                          </div>


                          <Badge name={`Prix total : ${stay?.prix_total} FCFA`} className="relative mr-2" />

                          {stay?.avance && stay?.avance?.montant > 0 && (
                            <Badge color="red" className="relative mr-2" name={`Dette : ${stay?.prix_total - stay?.avance?.montant} FCFA`} />
                          )}
                          {stay?.remboursement && stay?.remboursement?.montant > 0 && (
                            <Badge color="primary" name={`Rembourcement : ${stay?.remboursement?.montan} FCFA`} />
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Tab.Panel>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {reservations?.filter((_: any) => _?.type === 'CAVE').filter((_, i) => i < 4).map(
                    (stay: any) => (
                      <div
                        className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow`}
                        data-nc-id="StayCard"
                        key={stay.id}
                      >
                        <div className="p-4 space-y-4">
                          <div className="space-y-2">
                            <div className="flex items-center justify-between">
                              <Badge color="gray" name={`${stay?.reference}`} />

                              {stay?.etat === 'PAYER' && (
                                <Badge color="green" name={`Payée`} />
                              )}

                              {stay?.etat === 'IMPAYER' && (
                                <Badge color="red" name={`Non reglée`} />
                              )}

                              {stay?.etat === 'ANNULER' && (
                                <Badge color="gray" name={`Annulée`} />
                              )}
                            </div>

                            <div className="space-y-2 py-3">
                              <div>
                                <h2
                                  className={`font-medium capitalize text-base`}
                                >
                                  <span className="line-clamp-1">{stay?.cave?.nom}</span>
                                </h2>
                                <span className="text-sm text-neutral-500 dark:text-neutral-400 mb-5">
                                  {stay?.cave?.adresse}, {stay?.cave?.site?.adresse}
                                </span>
                              </div>

                              <span className="text-xs text-neutral-500 dark:text-neutral-400">
                                Reserver le {moment(stay?.date_reservation).locale('fr').format('LL')} pour {stay?.elements?.duree > 1 ? stay?.elements?.duree : 'une'} {stay?.elements?.periodicite === 'DEMI-JOUR' && 'démi-journée(s)'} {stay?.elements?.periodicite === 'NUITEE' && 'nuitée(s)'}
                              </span>
                            </div>


                            <Badge name={`Prix total : ${stay?.prix_total} FCFA`} className="relative mr-2" />

                            {stay?.commande && (
                              <Badge color="purple" className="relative mr-2" name={`Commande : ${stay?.commande?.prix_total} FCFA`} />
                            )}

                            {stay?.avance && stay?.avance?.montant > 0 && (
                              <Badge color="red" className="relative mr-2" name={`Dette : ${stay?.prix_total - stay?.avance?.montant} FCFA`} />
                            )}

                            {stay?.remboursement && stay?.remboursement?.montant > 0 && (
                              <Badge color="primary" name={`Rembourcement : ${stay?.remboursement?.montan} FCFA`} />
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </Tab.Panel>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {reservations?.filter((_: any) => _?.type === 'TABLE').filter((_, i) => i < 4).map((stay: any) => (
                    <div
                      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow`}
                      data-nc-id="StayCard"
                      key={stay.id}
                    >
                      <div className="p-4 space-y-4">
                        <div className="space-y-2">
                          <div className="flex items-center justify-between">
                            <Badge color="gray" name={`${stay?.reference}`} />

                            {stay?.etat === 'PAYER' && (
                              <Badge color="green" name={`Payée`} />
                            )}

                            {stay?.etat === 'IMPAYER' && (
                              <Badge color="red" name={`Non reglée`} />
                            )}

                            {stay?.etat === 'ANNULER' && (
                              <Badge color="gray" name={`Annulée`} />
                            )}
                          </div>

                          <div className="space-y-2 py-3">
                            <div>
                              <h2
                                className={`font-medium capitalize text-base`}
                              >
                                <span className="line-clamp-1">{stay?.elements?.table?.designation} de la cave {stay?.cave?.nom}</span>
                              </h2>
                              <span className="text-sm text-neutral-500 dark:text-neutral-400 mb-5">
                                {stay?.cave?.adresse}, {stay?.cave?.site?.adresse}
                              </span>
                            </div>

                            <span className="text-xs text-neutral-500 dark:text-neutral-400">
                              Reserver le {moment(stay?.date_reservation).locale('fr').format('LL')} pour {stay?.elements?.duree > 1 ? stay?.elements?.duree : 'une'} {stay?.elements?.periodicite === 'DEMI-JOUR' && 'démi-journée(s)'} {stay?.elements?.periodicite === 'NUITEE' && 'nuitée(s)'}
                            </span>
                          </div>


                          <Badge name={`Prix total : ${stay?.prix_total} FCFA`} className="relative mr-2" />

                          {stay?.commande && (
                            <Badge color="purple" className="relative mr-2" name={`Commande : ${stay?.commande?.prix_total} FCFA`} />
                          )}

                          {stay?.avance && stay?.avance?.montant > 0 && (
                            <Badge color="red" className="relative mr-2" name={`Dette : ${stay?.prix_total - stay?.avance?.montant} FCFA`} />
                          )}

                          {stay?.remboursement && stay?.remboursement?.montant > 0 && (
                            <Badge color="primary" name={`Rembourcement : ${stay?.remboursement?.montan} FCFA`} />
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div >
    );
  };

  return (
    <div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
      <Helmet>
        <title>{user?.nom ?? ''} {user?.prenom ?? ''} | LMDC Lounge</title>
      </Helmet>

      <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row">
        <div className="block flex-grow mb-24 lg:mb-0">
          <div className="lg:sticky lg:top-24">{renderSidebar()}</div>
        </div>
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
          {renderSection1()}
        </div>
      </main>
    </div>
  );
};

export default AuthorPage;
