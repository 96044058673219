import http from '../utils/campay-client';
import api from '../utils/http-client';
import localStorage from '../utils/localstorage';
import { store } from "core/store";
import { start, stop } from 'core/store/loader/reducer';

class PaymentService {
    pay(data: any) {
        const params = {
            amount: 5, // data.montant,
            currency: "XAF",
            from: '237' + data.numero,
            description: data.message,
            external_reference: "",
            external_user: ""
        };
        store.dispatch(start());
        return new Promise(async (resolve, reject) => {
            try {
                const rs = await http.post(`collect/`, params);
                store.dispatch(stop());
                resolve(rs.data);
            } catch (error) {
                store.dispatch(stop());
                reject(error);
            }
        });
    }

    save(id: any, data: any) {
        return new Promise(async (resolve, reject) => {
            try {
                const rs = await api.post(`pay/${id}`, data);
                resolve(rs.data);
            } catch (error) {
                reject(error);
            }
        });
    }

    checkPayment(reference: any) {
        return new Promise(async (resolve, reject) => {
            try {
                const rs = await http.get(`transaction/${reference}/`);
                resolve(rs.data);
            } catch (error) {
                reject(error);
            }
        });
    }

    token() {
        const data = {
            username: process.env.REACT_APP_CAMPAY_USERNAME,
            password: process.env.REACT_APP_CAMPAY_PASSWORD
        }
        return new Promise(async (resolve, reject) => {
            try {
                const rs = await http.post(`token/`, data);
                localStorage.set('campay-token', rs.data.token);
                resolve(rs);
            } catch (error) {
                reject(error);
            }
        });
    }
}

export default new PaymentService();