import http from '../utils//http-client';
import { store } from "core/store";
import { start, stop } from 'core/store/loader/reducer';
import { setCaves } from 'core/store/cave/reducer';

const source = 'cave'

class CaveService {

    all() {
        store.dispatch(start());
        return new Promise(async (resolve, reject) => {
            try {
                if (store.getState().cave.data?.length <= 0) {
                    const rs = await http.get(source);
                    store.dispatch(setCaves(rs?.data?.data));
                    store.dispatch(stop());
                    resolve(rs?.data?.data);
                } else {
                    store.dispatch(stop());
                    resolve(store.getState().cave.data);
                }
            } catch (error) {
                store.dispatch(stop());
                reject(error);
            }
        });
    }

    popular() {
        return new Promise(async (resolve, reject) => {
            try {
                const rs = await http.get(`${source}/popular`);
                resolve(rs);
            } catch (error) {
                reject(error);
            }
        });
    }

    one(id: any) {
        return new Promise(async (resolve, reject) => {
            try {
                let cave = null;
                if (store.getState().cave.data?.length <= 0) {
                    const rs: any = await this.all();
                    cave = rs?.find((cave: any) => cave?.id === +id);
                } else {
                    store.dispatch(start());
                    cave = store.getState().cave.data?.find((cave: any) => cave?.id === +id);
                }
                store.dispatch(stop());
                resolve(cave);
            } catch (error) {
                store.dispatch(stop());
                reject(error);
            }
        });
    }

    reserver(id: any, data: any) {
        return new Promise(async (resolve, reject) => {
            store.dispatch(start());
            try {
                const rs = await http.post(`${source}/${id}/reserver`, data);
                store.dispatch(stop());
                resolve(rs);
            } catch (error) {
                store.dispatch(stop());
                reject(error);
            }
        });
    }

    check(id: any, data: any) {
        return new Promise(async (resolve, reject) => {
            try {
                const rs = await http.post(`${source}/${id}/check`, data);
                resolve(rs.data);
            } catch (error) {
                reject(error);
            }
        });
    }
}

export default new CaveService();