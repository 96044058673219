import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { useParams, RouteComponentProps } from "react-router-dom";
import siteService from "core/services/site.service";
import bienService from "core/services/bien.service";

export interface ListingBienPageProps extends RouteComponentProps {
  className?: string;
}

const ListingBienPage: FC<ListingBienPageProps> = ({ className = "", match }) => {

  const [immeubles, setImmeubles] = useState([]);
  const [site, setSite] = useState<any>(null);
  const { site: siteId } = useParams<{ site?: any }>();

  const loadImmeuble = () => {
    bienService.all(siteId).then(
      (rs: any) => {
        setImmeubles(rs);
      }
    );
  }

  const loadSite = () => {
    siteService.one(siteId).then(
      (rs: any) => {
        setSite(rs);
        loadImmeuble();
      }
    );
  }

  useEffect(() => {
    loadSite();
  }, []);

  return (
    <div
      className={`nc-ListingBienPage relative overflow-hidden ${className}`}
      data-nc-id="ListingBienPage"
    >
      <Helmet>
        <title>{site?.adresse ?? 'Immeubles'} - LMDC Lounge</title>
      </Helmet>

      <BgGlassmorphism />

      <div className="container relative overflow-hidden">
        {/* SECTION HERO */}
        <SectionHeroArchivePage
          head={site?.adresse}
          desc={site?.description}
          listingType={(
            <React.Fragment>
              <i className="text-2xl las la-home"></i>
              <span className="ml-2.5">{immeubles?.length > 0 ? `${immeubles?.length} immeuble(s)` : `Aucun immeuble`}</span>
            </React.Fragment>
          )}
          className="pt-10 pb-5 lg:pb-10 lg:pt-24 "
        />

        {/* SECTION */}
        <SectionGridFilterCard data={immeubles} site={site} url={match.url} className="pb-24 lg:pb-32" />

        {/* SECTION */}
        <SectionSubscribe2 className="pb-24 lg:pb-32" />
      </div>
    </div>
  );
};

export default ListingBienPage;
