import React, { FC, useMemo, useState } from "react";
import StayCardI from "components/StayCardI/StayCardI";
import Pagination from "shared/Pagination/Pagination";
import Heading2 from "components/Heading/Heading2";
import StayCardSkeleton from "components/SkeletonCard/StayCardSkeleton";
import TabFilters from "./TabFilters";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: any[];
  site?: any;
  url?: string;
}

const PageSize = 8;

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data = [],
  site = null,
  url = ''
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const currentData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return data?.length > 0 ? data?.slice(firstPageIndex, lastPageIndex) : [];
  }, [currentPage, data]);

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2 heading={`Explorer les immeubles de "${site?.adresse}"`} subHeading={(
        <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
          {data?.length > 0 ? `${data?.length} immeuble(s)` : `Aucun immeuble`}
        </span>
      )} />

      <div className="mb-8 lg:mb-11">
        <TabFilters />
      </div>

      {data?.length <= 0 ? (
        <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {[1, 2, 3].map((stay) => (
            <StayCardSkeleton key={stay} />
          ))}
        </div>
      ) : (
        <React.Fragment>
          <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {currentData.map((immeuble) => (
              <StayCardI key={immeuble?.id} data={immeuble} url={url} />
            ))}
          </div>
          <div className="flex mt-16 justify-center items-center">
            <Pagination
              currentPage={currentPage}
              totalCount={data?.length}
              pageSize={PageSize}
              onPageChange={page => setCurrentPage(page)}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default SectionGridFilterCard;
