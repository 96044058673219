import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory, RouteProps } from "react-router-dom";
import { useForm } from "react-hook-form";

import authService from "core/services/auth.service";

import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CheckBox from "shared/Checkbox/Checkbox";
import FormItem from "shared/FormItem/FormItem";

export interface PageLoginProps extends RouteProps {
  className?: string;
  location?: any
}

const PageLogin: FC<PageLoginProps> = ({ className = "", location }) => {

  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      email: null,
      password: null
    }
  });

  const [remember, setRemember] = useState(true)

  const history = useHistory();

  const handelConnexion = async (data: any) => {
    data.remember = remember;
    await authService.login(data);
    history.push(location?.state?.from || "/sites");
  }

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Connexion - LMDC Lounge</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Connexion
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(handelConnexion)}>
            <FormItem
              label="Email"
              className="block"
              error={errors.email && "L'email est obligatoire !"}
              required
            >
              <Input
                error={errors.email ? true : false}
                type="email"
                {...register("email", { required: true })}
                placeholder="Ex: example@example.com"
                className="mt-1"
              />
            </FormItem>

            <FormItem
              label="Mot de passe"
              className="block"
              error={errors.password && "Le mot de passe est obligatoire !"}
              required
            >
              <Input
                error={errors.password ? true : false}
                {...register("password", { required: true })}
                type="password"
                className="mt-1"
              />
            </FormItem>
            <CheckBox
              name="remember"
              defaultChecked={remember}
              onChange={() => setRemember(!remember)}
              label="Se souvenir de moi"
            />

            <ButtonPrimary type="submit">Continuer</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Nouvel utilisateur? {` `}
            <Link to="/signup">Créer un compte</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
