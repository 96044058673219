import localstorage from 'core/utils/localstorage';
import http from '../utils//http-client';
import { store } from "core/store";
import { setUser } from 'core/store/user/reducer';
import { start, stop } from 'core/store/loader/reducer';

const source = 'user'

class UserService {
    find(id: any) {
        return new Promise(async (resolve, reject) => {
            try {
                const rs = await http.get(`${source}/${id}`);
                localstorage.setJson('user', rs?.data?.data);
                store.dispatch(setUser(rs?.data?.data));
                resolve(rs.data);
            } catch (error) {
                reject(error);
            }
        });
    }

    findReservation(id: any) {
        return new Promise(async (resolve, reject) => {
            try {
                const rs = await http.get(`${source}/${id}/reservations`);
                resolve(rs.data);
            } catch (error) {
                reject(error);
            }
        });
    }

    updateProfil(id: any, profil: FormData) {
        return new Promise(async (resolve, reject) => {
            store.dispatch(start());
            try {
                const rs = await http.post(`${source}/${id}/update/profil`, profil);
                localstorage.setJson('user', rs?.data?.data);
                store.dispatch(setUser(rs?.data?.data));
                store.dispatch(stop());
                resolve(rs.data);
            } catch (error) {
                store.dispatch(stop());
                reject(error);
            }
        });
    }

    update(id: any, data: any) {
        return new Promise(async (resolve, reject) => {
            store.dispatch(start());
            try {
                const rs = await http.post(`${source}/${id}/update`, data);
                localstorage.setJson('user', rs?.data?.data);
                store.dispatch(setUser(rs?.data?.data));
                store.dispatch(stop());
                resolve(rs.data);
            } catch (error) {
                store.dispatch(stop());
                reject(error);
            }
        });
    }

    contact(data: any) {
        return new Promise(async (resolve, reject) => {
            store.dispatch(start());
            try {
                const rs = await http.post(`send/mail`, data);
                store.dispatch(stop());
                resolve(rs.data);
            } catch (error) {
                store.dispatch(stop());
                reject(error);
            }
        });
    }

    updatePassword(id: any, data: any) {
        return new Promise(async (resolve, reject) => {
            store.dispatch(start());
            try {
                const rs = await http.post(`${source}/${id}/update/password`, data);
                store.dispatch(stop());
                resolve(rs.data);
            } catch (error) {
                store.dispatch(stop());
                reject(error);
            }
        });
    }
}

export default new UserService();