import React from "react";
import { Link } from "react-router-dom";
import logoImg from "images/cave-logo.jpeg";
import logoLightImg from "images/cave-logo.jpeg";
// import LogoSvgLight from "./LogoSvgLight";
// import LogoSvg from "./LogoSvg";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "",
}) => {
  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-primary-6000 ${className}`}
    >
      {/* <LogoSvgLight /> */}
      {/* <LogoSvg /> */}

      {/* THIS USE FOR MY CLIENT */}
      {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
      {img ? (
        <img
          className={`block max-h-12 rounded-lg shadow-xl ${imgLight ? "dark:hidden" : ""}`}
          src={img}
          alt="LMDC Lounge"
        />
      ) : (
        "LCDN"
      )}

      {imgLight && (
        <img
          className="hidden max-h-12 rounded-lg shadow-xl dark:block"
          src={imgLight}
          alt="LMDC Lounge"
        />
      )}
    </Link>
  );
};

export default Logo;
