import React, { FC } from "react";

import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'

export interface SiteSkeletonProps {
    className?: string;
    size?: "default" | "small";
}

const SiteSkeleton: FC<SiteSkeletonProps> = ({
    size = "default",
    className = "",
}) => {
    const renderContent = () => {
        return (
            <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
                <div className="space-y-2">
                    <div className="space-x-2 py-5 pt-0">
                        <Skeleton />
                    </div>
                    <span className="text-sm text-neutral-500 dark:text-neutral-400">
                        <Skeleton count={2} />
                    </span>
                </div>
            </div>
        );
    };

    return (
        <div
            className={`nc-SiteSkeleton group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
            data-nc-id="SiteSkeleton"
        >
            {renderContent()}
        </div>
    );
};

export default SiteSkeleton;
