import React, { FC, useEffect, useState } from "react";
import MainDatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import fr from 'date-fns/locale/fr';
import "react-datepicker/dist/react-datepicker.css";

registerLocale('fr', fr)

export interface DatePickerProps {
    sizeClass?: string;
    fontClass?: string;
    rounded?: string;
    error?: boolean;
    className?: string;
    showTimeSelect?: boolean;
    dateFormat?: string
    defaultValue?: Date | null,
    onChange?: (date: Date) => void,
    excludeDates?: Date[];
    highlightDates?: Date[];
    excludeTimes?: Date[]
}

const DatePicker: FC<DatePickerProps> = ({
    defaultValue = null,
    className = "",
    sizeClass = "h-11 px-4 py-3",
    fontClass = "text-sm font-normal",
    rounded = "rounded-2xl",
    error = false,
    showTimeSelect = false,
    dateFormat = "dd/MM/yyyy",
    onChange = (date: Date) => { },
    excludeDates = [],
    highlightDates = [],
    excludeTimes = []
}) => {
    const [selectedDate, setSelectedDate] = useState(defaultValue);

    useEffect(() => {
        setSelectedDate(defaultValue);
    }, [defaultValue]);

    const handleDateChange = (date: Date) => {
        onChange(date);
        setSelectedDate(date);
    }

    return (
        <MainDatePicker
            className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 ${error ? 'border-red-500' : ''} ${rounded} ${fontClass} ${sizeClass} ${className}`}
            selected={selectedDate}
            minDate={new Date()}
            excludeDates={excludeDates}
            highlightDates={highlightDates}
            excludeTimes={excludeTimes}
            onChange={(date: any) => handleDateChange(date)}
            timeFormat="HH:mm"
            showTimeSelect={showTimeSelect}
            dateFormat={dateFormat}
            locale="fr"
        />
    );
};

export default DatePicker;
