import React, { FC, useState, useEffect } from "react";
import { DayPickerSingleDateController } from "react-dates";
import moment from "moment";

export interface DateDisponibleProps {
    daySize?: number;
    size?: number;
    dates?: any[];
}

const DateDisponible: FC<DateDisponibleProps> = ({
    daySize = 48,
    size = 2,
    dates = [],
}) => {
    const [value, setValue] = useState(dates);

    const uniqueData = (data: any) => {
        return data.filter((value: any, index: any, self: any) => {
            return self.indexOf(value) === index;
        });
    }

    const getDaysBetweenDates = (allDates: any[]) => {
        let dates: any[] = [];
        allDates?.forEach((element: any) => {
            let now = moment(element?.debut).clone();
            while (now.isSameOrBefore(moment(element?.fin).subtract(1, 'days'))) {
                dates.push(now.format('YYYY-MM-DD'));
                now.add(1, 'days');
            }
        });
        return uniqueData(dates);
    };

    const isBlocked = (day: any) => {
        let availableDates: any[] = [];
        const allDates = value?.map((item: any) => {
            return {
                debut: item?.date_debut,
                fin: item?.date_fin
            }
        });
        getDaysBetweenDates(allDates).forEach((date: any) => {
            availableDates.push(date);
        });
        return availableDates.some(date => day.isSame(date, 'day'));;
    }

    useEffect(() => {
        setValue(dates);
    }, [dates]);

    return (
        <DayPickerSingleDateController
            date={null}
            onDateChange={() => { }}
            onFocusChange={() => { }}
            focused
            initialVisibleMonth={null}
            numberOfMonths={size}
            daySize={daySize}
            hideKeyboardShortcutsPanel
            isDayHighlighted={isBlocked}
            isOutsideRange={isBlocked}
        />
    );
};

export default DateDisponible;
