import { AppState } from './../index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User, UserState } from "./types";
import localstorage from '../../utils/localstorage';

const initialState: UserState = {
    data: localstorage.exist('user') ? localstorage.getJson('user') : null
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<User>) => {
            state.data = action.payload
        },
    
        removeUser: (state) => {
            state.data = null
        }
    },
})


export const { setUser, removeUser } = userSlice.actions

export const getUser = (state: AppState) => state.user.data

export default userSlice.reducer