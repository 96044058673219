import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Loader from 'components/Loader/Loader';
import { connect } from 'react-redux'

import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import Header from "shared/Header/Header";
import Page404 from "containers/Page404/Page404";
//
import ListingSitePage from "containers/ListingSitePage/ListingSitePage";
import ListingBienPage from "containers/ListingBienPage/ListingBienPage";
import ListingCavePage from "containers/ListingCavePage/ListingCavePage";
import ListingExperiencesDetailPage from "containers/ListingDetailPage/ListingExperiencesDetailPage";
import ListingMeubleDetailPage from "containers/ListingMeublePage/ListingMeubleDetailPage";
import ListingCaveDetailPage from "containers/ListingCavePage/ListingCaveDetailPage";
import ListingMeublePage from "containers/ListingMeublePage/ListingMeublePage";
//
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PayPage from "containers/PayPage/PayPage";
//
import AuthorPage from "containers/AuthorPage/AuthorPage";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountBilling from "containers/AccountPage/AccountBilling";
//
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
//
import PageHome2 from "containers/PageHome/PageHome2";
import { AppState } from "core/store";

export const pages: any[] = [
  { path: "/", exact: true, component: PageHome2 },
  { path: "/#", exact: true, component: PageHome2 },
  { path: "/home-2", component: PageHome2 },
  //
  { path: "/sites", exact: true, component: ListingSitePage },
  { path: "/accountP", exact: true, component: AccountPage },
  { path: "/sites/:site/immeubles", exact: true, component: ListingBienPage },
  { path: "/sites/:site/immeubles/:immeuble/meubles", exact: true, component: ListingMeublePage },
  { path: "/sites/:site/immeubles/:immeuble/meubles/:meuble", exact: true, component: ListingMeubleDetailPage },
  //
  { path: "/caves", exact: true, component: ListingCavePage  },
  { path: "/caves/:cave", exact: true, component: ListingCaveDetailPage },
  { path: "/caves/check", exact: true, component: ListingExperiencesDetailPage },
  //
  { path: "/checkout", component: CheckOutPage },
  { path: "/pay-done", component: PayPage },
  //
  { path: "/author", component: AuthorPage },
  { path: "/account", component: AuthorPage },
  { path: "/account-password", component: AccountPass },
  { path: "/account-savelists", component: AccountSavelists },
  { path: "/account-billing", component: AccountBilling },
  //
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin }
  //
];

const mapState = (state: AppState) => ({
  isLoading: state.loader.loading,
})

const connector = connect(mapState)

const Routes = ({ isLoading } : { isLoading: boolean }) => {

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  return (
    <BrowserRouter basename="/">
      <Loader active={loading}>
        <ScrollToTop />
        <Header />
        <Switch>
          {pages.map(({ component, path, exact }) => {
            return (
              <Route
                key={path}
                component={component}
                exact={!!exact}
                path={path}
              />
            );
          })}
          <Route component={Page404} />
        </Switch>
        <Footer />
      </Loader>
    </BrowserRouter>
  );
};

export default connector(Routes);
