import React, { FC } from "react";
import { Link } from "react-router-dom";
import Badge from "shared/Badge/Badge";
import placeholder from '../../images/meuble-placeholder.png';
import NcImage from "shared/NcImage/NcImage";

import NumberFormat from 'react-number-format';

export interface PropertyCardHProps {
  className?: string;
  data?: any;
  url?: string;
}

const PropertyCardH: FC<PropertyCardHProps> = ({
  className = "",
  data = null,
  ...props
}) => {
  const { url } = props;

  const renderSliderGallery = () => {
    return (
      <div className="flex-shrink-0 p-3 sm:w-64">
        <NcImage
          src={data?.image_principale ? data?.image_principale?.path : placeholder}
          styleImg={{ height: '12rem' }}
          containerClassName="rounded-2xl overflow-hidden"
          className="object-cover w-full"
        />
      </div>
    );
  };

  const renderTienIch = () => {
    return (
      <div className="flex items-center">
        {data?.type !== 'CHAMBRE' && (
          <div className="flex items-center justify-center space-x-2 mr-3">
            <span className="hidden sm:inline-block">
              <i className="las la-bed text-lg"></i>
            </span>
            <span className="text-xs text-neutral-500 dark:text-neutral-400">
              {data?.chambre} chambre(s)
            </span>
          </div>
        )}

        {/* ---- */}
        <div className="flex items-center justify-center space-x-2 mr-3">
          <span className="hidden sm:inline-block">
            <i className="las la-bath text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {data?.toilet} toilette(s)
          </span>
        </div>

        {/* ---- */}
        <div className="flex items-center justify-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-expand-arrows-alt text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {data?.superficie} m<sup>2</sup>
          </span>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
        <div className="space-y-4 w-full">
          <div className="inline-flex space-x-3">
            <Badge
              name={
                <div className="flex items-center">
                  {data?.type_complet}
                </div>
              }
            />
          </div>
          <div className="flex items-center space-x-2">
            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-2">{data?.designation}</span>
            </h2>
          </div>
          {renderTienIch()}
          <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 "></div>
          <div className="flex w-full justify-between items-end">
            <span className="flex items-center justify-center px-3 py-2 border border-secondary-500 rounded leading-none text-base font-medium text-secondary-500">
              <span className="mr-1">À partir de</span>  <NumberFormat defaultValue={0} value={data?.prix_jour} displayType={'text'} thousandSeparator={' '} suffix={' FCFA'} />
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-PropertyCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="PropertyCardH"
    >
      <Link
        to={`${url}/${data?.id}`}
        className="h-full w-full flex flex-col sm:flex-row sm:items-center"
      >
        {renderSliderGallery()}
        {renderContent()}
      </Link>
    </div>
  );
};

export default PropertyCardH;
