import React, { FC } from "react";
import { Link } from "react-router-dom";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import placeholder from '../../images/cave-placeholder.png';
import NcImage from "shared/NcImage/NcImage";

export interface ExperiencesCardProps {
  className?: string;
  ratioClass?: string;
  data?: any;
  size?: "default" | "small";
}

const ExperiencesCard: FC<ExperiencesCardProps> = ({
  size = "default",
  className = "",
  data = null,
  ratioClass = "aspect-w-3 aspect-h-3",
}) => {

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <NcImage src={data?.image_principale ? data?.image_principale?.path : placeholder} styleImg={{ height: '15rem' }} className="object-cover w-full" />
        { data?.parking === 1 && <SaleOffBadge desc="Avec parking" className="absolute left-3 top-3" /> }
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <div className="flex flex-wrap items-center justify-between text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            <span className="text-sm text-neutral-500 dark:text-neutral-400 truncate" style={{ width: '70%' }}>
              <i className="las la-map-marked-alt"></i> {data?.adresse}, {data?.site?.adresse}
            </span>
            <span className="text-sm text-neutral-500 dark:text-neutral-400 p-1">
              {data?.tables?.length} table(s)
            </span>
          </div>

          <div className="flex items-center space-x-2 pt-2">
            <h2
              className={` font-medium capitalize ${size === "default" ? "text-lg" : "text-base"
                }`}
            >
              <span className="line-clamp-1">{data?.nom}</span>
            </h2>
          </div>

          <div className="flex flex-wrap items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            <span className="text-sm text-neutral-500 dark:text-neutral-400 mt-2 truncate" style={{ width: '45%' }}>
              <span className="la la-at"></span> {data?.email}
            </span>
            <span className="text-sm text-neutral-500 dark:text-neutral-400 mt-2">
              .
            </span>
            <span className="text-sm text-neutral-500 dark:text-neutral-400 mt-2 truncate" style={{ width: '45%' }}>
              <span className="la la-phone-alt"></span> {data?.telephone}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-ExperiencesCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="ExperiencesCard"
    >
      <Link to={`caves/${data?.id}`}>
        {renderSliderGallery()}
        {renderContent()}
      </Link>
    </div>
  );
};

export default ExperiencesCard;
