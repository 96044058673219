import localstorage from 'core/utils/localstorage';
import http from '../utils//http-client';
import { store } from "core/store";
import { start, stop } from 'core/store/loader/reducer';
import { setUser } from 'core/store/user/reducer';
import { setToken, removeToken, userConnect, userDisconnect } from 'core/store/auth/reducer';

const source = 'auth'

class AuthService {

    login(credentials: any) {
        return new Promise(async (resolve, reject) => {
            store.dispatch(start());
            try {
                const rs: any = await http.post(`${source}/login`, credentials);
                localstorage.setJson('user', rs?.data?.data?.user);
                localstorage.set('token', rs?.data?.data?.token);
                store.dispatch(setUser(rs?.data?.data?.user));
                store.dispatch(setToken(rs?.data?.data?.token));
                store.dispatch(userConnect());
                store.dispatch(stop());
                resolve(rs);
            } catch (error) {
                store.dispatch(stop());
                reject(error);
            }
        });
    }

    logout() {
        return new Promise(async (resolve, reject) => {
            store.dispatch(start());
            try {
                const rs = await http.get(`${source}/logout`);
                localstorage.remove('user');
                localstorage.remove('token');
                store.dispatch(removeToken());
                store.dispatch(userDisconnect());
                store.dispatch(stop());
                resolve(rs);
            } catch (error) {
                store.dispatch(stop());
                reject(error);
            }
        });
    }

    register(user: any) {
        return new Promise(async (resolve, reject) => {
            store.dispatch(start());
            try {
                const rs = await http.post(`${source}/register`, user);
                store.dispatch(stop());
                resolve(rs);
            } catch (error) {
                store.dispatch(stop());
                reject(error);
            }
        });
    }

    forgotPassword() {
        return new Promise(async (resolve, reject) => {
            store.dispatch(start());
            try {
                const rs = await http.get(`${source}/forgot/password`);
                store.dispatch(stop());
                resolve(rs);
            } catch (error) {
                store.dispatch(stop());
                reject(error);
            }
        });
    }

    resetPassword() {
        return new Promise(async (resolve, reject) => {
            store.dispatch(start());
            try {
                const rs = await http.get(`${source}/reset/password`);
                store.dispatch(stop());
                resolve(rs);
            } catch (error) {
                store.dispatch(stop());
                reject(error);
            }
        });
    }

}

export default new AuthService();