// import Label from "components/Label/Label";
import React, { useState, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import FormItem from "shared/FormItem/FormItem";
import CommonLayout from "./CommonLayout";

import { useAppSelector } from "core/store";

import userService from "core/services/user.service";
import Alert from '../../core/utils/alert';

const AccountPass = () => {

  const user = useAppSelector(state => state.user.data);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [errorConfirm, setErrorConfirm] = useState(false);

  useEffect(() => {
    if (confirmNewPassword !== "" && newPassword !== "") {
      if (confirmNewPassword !== newPassword) {
        setErrorConfirm(true);
      } else {
        setErrorConfirm(false);
      }
    }
  }, [newPassword, confirmNewPassword])

  const handelUpdatePassword = () => {
    if (newPassword !== confirmNewPassword) {
      return;
    };

    const data = {
      old: oldPassword,
      new: newPassword
    };

    userService.updatePassword(user?.id, data).then(
      (rs: any) => {
        setOldPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
        Alert.fire({
          title: 'Mot de passe mise à jour!',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'Okay'
        });
      }
    )
  }

  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Mise à jour du mot de passe</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className=" max-w-xl space-y-6">
            <FormItem
              label="Mot de passe actuel"
              className="block"
            >
              <Input
                type="password"
                className="mt-1.5"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </FormItem>
            <FormItem
              label="Nouveau mot de passe"
              className="block"
            >
              <Input
                error={errorConfirm}
                type="password"
                className="mt-1.5"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </FormItem>

            <FormItem
              label="Confirmez le nouveau mot de passe"
              className="block"
              error={errorConfirm ? "Doit être identique avec le nouveau mot de passe" : ""}
            >
              <Input
                error={errorConfirm}
                type="password"
                className="mt-1.5"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
            </FormItem>
            <div className="pt-2">
              <ButtonPrimary onClick={() => handelUpdatePassword()}>Enregistrer</ButtonPrimary>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;
