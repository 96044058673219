import React, { FC } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonError from "shared/Button/ButtonError";
import MenuBar from "shared/MenuBar/MenuBar";
// import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import { connect, ConnectedProps } from 'react-redux'
import { AppState } from "core/store";
import { User } from "core/store/user/types";
// import { removeToken, userDisconnect } from "core/store/auth/reducer";
// import { removeUser } from "core/store/user/reducer";

import authService from "core/services/auth.service";

// import { useHistory } from "react-router-dom";

export interface MainNav1Props extends PropsFromRedux {
  isTop: boolean;
  isConnected: boolean;
  user: User | null;
}

const mapState = (state: AppState) => ({
  isConnected: state.auth.connected,
  user: state.user.data,
})

type PropsFromRedux = ConnectedProps<typeof connector>

const connector = connect(mapState)

const MainNav1: FC<MainNav1Props> = ({ isTop, isConnected, user }) => {

  // const history = useHistory();

  const handelLogout = async () => {
    await authService.logout();
  }

  return (
    <div
      className={`nc-MainNav1 relative z-10 ${isTop ? "onTop " : "notOnTop backdrop-filter"
        }`}
    >
      <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
        <div className="flex justify-start flex-grow items-center space-x-4 sm:space-x-10 2xl:space-x-14">
          <Logo />
          <Navigation />
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center xl:flex space-x-1">
            {/* <SwitchDarkMode /> */}
            <SearchDropdown />
            {isConnected ? (
              <React.Fragment>
                <div className="px-1" />
                <ButtonError fontSize="text-xs" onClick={() => handelLogout()}>Déconnexion</ButtonError>
                <div className="px-1" />
                <ButtonPrimary href="/account" fontSize="text-xs"> {user?.nom_complet} </ButtonPrimary>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="px-1" />
                <ButtonPrimary href="/login" fontSize="text-xs">Connexion</ButtonPrimary>
              </React.Fragment>
            )}
          </div>
          <div className="flex items-center xl:hidden">
            {/* <SwitchDarkMode /> */}
            <div className="px-1" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connector(MainNav1);
