import React from "react";

import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'

const MeubleCardSkeleton = () => {

    const renderContent = () => {
        return (
            <div className="h-full w-full flex flex-col sm:flex-row sm:items-center">
                <div className="flex-shrink-0 p-3 sm:w-64">
                    <Skeleton height={200} />
                </div>

                <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
                    <div className="space-y-4 w-full">
                        <Skeleton width={150} />

                        <div className="space-x-2">
                            <Skeleton count={2} />
                        </div>

                        <div className="flex">
                            <div className="space-x-2 mr-3">
                                <Skeleton width={100} />
                            </div>
                            <div className="space-x-2 mr-3">
                                <Skeleton width={100} />
                            </div>
                            <div className="space-x-2 mr-3">
                                <Skeleton width={100} />
                            </div>
                        </div>

                        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 "></div>
                        <div className="w-full">
                            <Skeleton />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div
            className={`nc-PropertyCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow`}
            data-nc-id="PropertyCardH"
        >
            {renderContent()}
        </div>
    );
};

export default MeubleCardSkeleton;