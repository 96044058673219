import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import React, { FC, useEffect, useState } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";
import { useParams, RouteComponentProps } from "react-router-dom";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import siteService from "core/services/site.service";
import bienService from "core/services/bien.service";
import meubleService from "core/services/meuble.service";

export interface ListingMeublePageProps extends RouteComponentProps {
  className?: string;
}

const ListingMeublePage: FC<ListingMeublePageProps> = ({
  className = "",
  ...props
}) => {

  const { match } = props;

  const [meubles, setMeubles] = useState<any[]>([]);
  const [immeuble, setImmeuble] = useState<any>(null);
  const [site, setSite] = useState<any>(null);
  const { immeuble: immeubleId, site: siteId } = useParams<{ immeuble?: any, site?: any }>();

  const loadMeubles = () => {
    meubleService.all(immeubleId).then(
      (rs: any) => {
        setMeubles(rs);
      }
    )
  }

  const loadSite = () => {
    siteService.one(siteId).then(
      (rs: any) => {
        setSite(rs);
        loadImmeuble();
      }
    )
  }

  const loadImmeuble = () => {
    bienService.one(immeubleId, siteId).then(
      (rs: any) => {
        setImmeuble(rs);
        loadMeubles();
      }
    )
  }

  useEffect(() => {
    loadSite();
  }, []);

  return (
    <div
      className={`nc-ListingMeublePage relative overflow-hidden ${className}`}
      data-nc-id="ListingMeublePage"
    >
      <Helmet>
        <title>{immeuble?.nom ?? 'Meublés'} - LMDC Lounge</title>
      </Helmet>

      <div className="container relative">
        {/* SECTION HERO */}
        <SectionHeroArchivePage
          head={immeuble?.nom}
          desc={immeuble?.description}
          listingType={(
            <React.Fragment>
              <div className="my-2" style={{ paddingRight: '2.5rem' }}>
                <i className="las la-map-marked"></i>
                <span className="ml-1"> {site?.adresse} - {immeuble?.adresse}</span>
              </div>
              <div className="my-2" style={{ paddingRight: '2.5rem' }}>
                <i className="las la-home"></i>
                <span className="ml-1"> {immeuble?.meubles_count > 0 ? immeuble?.meubles_count : 'Aucun'} meublé(s)</span>
              </div>
              <div className="my-2" style={{ paddingRight: '2.5rem' }}>
                <i className="las la-at"></i>
                <span className="ml-1"> {immeuble?.email}</span>
              </div>
              <div className="my-2">
                <i className="las la-phone"></i>
                <span className="ml-1"> {immeuble?.telephone}</span>
              </div>
            </React.Fragment>
          )}
          className="pt-10 pb-5 lg:pb-10 lg:pt-24"
        />

        {/* SECTION */}
        <SectionGridFilterCard data={meubles} immeuble={immeuble} url={match.url} className="pb-24 lg:pb-32" />

        {/* SECTION */}
        <SectionSubscribe2 className="pb-24 lg:pb-32" />
      </div>
    </div>
  );
};

export default ListingMeublePage;
