import React, { FC, useState } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import rightImg from "images/newsletter.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import Input from "shared/Input/Input";

import newsLetterService from "core/services/newsletter.service";

import Alert from 'core/utils/alert';

export interface SectionSubscribe2Props {
  className?: string;
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = "" }) => {
  const [email, setEmail] = useState('');
  const [saving, setSaving] = useState(false);

  const handelEmailChange = (value: string) => {
    setEmail(value);
  }

  const handelNewsletterSubscribe = async () => {
    setSaving(true);
    await newsLetterService.subcribe(email).then(
      (rs) => {
        setSaving(false);

        Alert.fire(
          'Merci de vous inscrire à notre newsletter!',
          `<p>Vous recevrez par mail des promotions sur nos chambres et des boissons dans nos caves.</p>`,
          'success'
        );

        setEmail('');
      },
      (error) => {
        setSaving(false);
      }
    );
  }
  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
      data-nc-id="SectionSubscribe2"
    >
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-4xl text-center lg:text-left">Rejoignez notre newsletter</h2>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400 text-center lg:text-left">
          Lisez et partagez de nouvelles perspectives sur n'importe quel sujet. Tout le monde est bienvenue.
        </span>
        <ul className="space-y-4 mt-10">
          <li className="flex items-center space-x-4">
            <Badge name="01" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Obtenez plus de réductions
            </span>
          </li>
          <li className="flex items-center space-x-4">
            <Badge color="red" name="02" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Obtenez des magazines premium
            </span>
          </li>
        </ul>
        <div className="mt-10 relative max-w-sm">
          <Input
            required
            aria-required
            placeholder="Entrez votre email"
            type="email"
            onChange={(e) => handelEmailChange(e.target.value)}
            value={email}
          />
          <ButtonCircle
            type="button"
            className="absolute transform top-1/2 -translate-y-1/2 right-1"
            onClick={() => handelNewsletterSubscribe()}
          >
            <i className={`las la-${saving ? 'spinner animate-spin' : 'arrow-right'} text-xl`}></i>
          </ButtonCircle>
        </div>
      </div>
      <div className="flex-grow">
        <NcImage src={rightImg} width="450" containerClassName="flex justify-center" />
      </div>
    </div>
  );
};

export default SectionSubscribe2;
